import ModalUpload from 'containers/Modal/modal-documents-upload'
import DebtCapacityForm from 'containers/debt-capacity/debt-capacity-form'
import { validateFormErrors } from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { getDocumentList, uploadDocuments } from 'services/documents'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { extractNumber } from 'utils/functions/handlers-currency'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import {
  ASSESSMENT_FILES_SCHEMA,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import styled from 'styled-components'
import { v4 as UUIDv4 } from 'uuid'

const UploadProposal = () => {
  const [files, setFiles] = useState({})
  const [formValues, setFormValues] = useState([
    { id: UUIDv4(), currency: '', score: '', maxTerm: 0, allowEdit: true },
  ])
  const [formErrors, setFormErrors] = useState([
    { currency: true, score: true, maxTerm: true },
  ])
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const formRef = useRef(null)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isSavingFiles, setIsSavingFiles] = useState(false)
  const [modalConfig, setModalConfig] = useState({})

  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const { user } = useAuth()
  const { customerId } = useParams()
  const navigate = useNavigate()

  const createProposal = () => {
    try {
      setIsValidating(true)
      validateFormValues()
      validateDocuments()
      executeTransaction()
    } catch (error) {
      setOpenModalUpload(false)
    }
  }

  const saveDocuments = async () => {
    try {
      setIsLoadingSubmit(true)
      await uploadFileList()
      setAction(false)
      setOpenModalUpload(false)
    } catch (error) {
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'file-upload-error') {
      }
      setAlert(alert)
    } finally {
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setIsLoadingSubmit(false)
      navigate(-1)
    }
  }

  const uploadFileList = async () => {
    const [userRole] = getUserRoles()
    const response = await uploadDocuments(
      files,
      customerId,
      user.uid,
      userRole
    )

    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('file-upload-error')
    }
    return response.map((request) => request.value)
  }

  const executeTransaction = async () => {
    try {
      setIsLoadingSubmit(true)

      const { transition } = ONBOARDING_STATES.CREATE_PROPOSAL
      const { nextState, nextStage, event } = transition.uploadProposal

      const multiUploadIds = await uploadFileList()

      const debtCapacities = formValues.map(({ amount, currency, maxTerm }) => {
        return {
          id: UUIDv4(),
          amount: extractNumber(amount),
          currency,
          maxTerm,
        }
      })

      const transitionData = {
        'customerId:assignation': Number(customerId),
        'uuid:assignation': user.uid,
        'fileList:multiUpdate': multiUploadIds,
        'id:status': Number(customerId),
        'wfState:status': nextState,
        'wfStage:status': nextStage,
        'customerId:debtCapacity': Number(customerId),
        'id:debtCapacity': UUIDv4(),
        'debtCapacities:debtCapacity': debtCapacities,
      }

      await ApiBuyerRepository.doEvent(customerId, event, transitionData)

      navigate(Router.Root)
    } catch (error) {
      let label = 'Ha ocurrido un error al crear la propuesta de crédito.'
      if (error.message === 'file-upload-error') {
        label =
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo'
      }
      setAlert({ title: 'Error', type: 'error', label })
      navigate(-1)
    } finally {
      setAction(false)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    }
  }

  const validateFormValues = () => {
    if (formErrors.some(validateFormErrors)) {
      setAlert({
        label: `Debes completar correctamente el formulario y
          cargar una propuesta comercial para continuar`,
        title: 'Error',
        type: 'error',
      })
      formRef.current.scrollIntoView({ behavior: 'smooth' })
      throw new Error('FormError')
    }

    const countCapacities = formValues.reduce((acc, capacity) => {
      const value = acc[capacity.currency] ?? 0
      acc[capacity.currency] = value + 1
      return acc
    }, {})

    const isCurrencyDuplicated = Object.values(countCapacities).some(
      (value) => value > 1
    )

    if (isCurrencyDuplicated) {
      setAlert({
        label: `No puede haber una moneda con dos o más capacidades de endeudamiento`,
        title: 'Error',
        type: 'error',
      })
      formRef.current.scrollIntoView({ behavior: 'smooth' })
      throw new Error('CurrencyDuplicated')
    }
  }

  const validateDocuments = () => {
    const documentsSchema = ASSESSMENT_FILES_SCHEMA
    if (documentsSchema.some(({ typeId }) => !files[typeId]?.length > 0)) {
      setAlert({
        label: `Es necesario que realice la carga de todos
          los documentos solicitados para continuar`,
        title: 'Error',
        type: 'error',
      })
      throw new Error('DocumentsError')
    }
  }

  const openModalConfirmation = (isSaving) => {
    setOpenModalUpload(true)
    setIsSavingFiles(isSaving)

    let messageConfig = {}
    if (!isSaving) {
      messageConfig = {
        msg: `
            Estas a punto de enviar la propuesta de crédito,
            esta acción cambiará el estado del cliente.
            ¿Estás seguro de continuar?
          `,
        msgButton: 'Enviar',
      }
    }
    setModalConfig(messageConfig)
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    const fetchFiles = async () => {
      if (!customerId) return

      const { data: savedFilesData } = await getDocumentList(customerId, {
        status: `${FILE_STATES.PENDING},${FILE_STATES.ACCEPTED},${FILE_STATES.REFUSED},${FILE_STATES.DRAFT}`,
        stage: FILE_STAGES.ASSESSMENT,
      })

      const savedFiles = {}

      savedFilesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!savedFiles[itm.typeId]) savedFiles[itm.typeId] = []
        savedFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
        })
      })
      setFiles(savedFiles)
    }

    fetchFiles()
  }, [customerId])

  return (
    <DocumentsUploadStyled ref={formRef}>
      <h1>Assessment</h1>
      <section>
        <h2>Capacidad de endeudamiento</h2>
        <DebtCapacityForm
          debtCapacities={formValues}
          formErrors={formErrors}
          setDebtCapacities={setFormValues}
          setFormErrors={setFormErrors}
          isValidating={isValidating}
        />
      </section>

      <section className='customer-documents'>
        <h2>Documentos Assessment</h2>
        <BuildSection
          allowDelete
          allowUpdate
          files={files}
          isValidating={isValidating}
          schema={ASSESSMENT_FILES_SCHEMA}
          setFiles={setFiles}
          setCommentBarInfo={setOpenCommentBar}
        />
      </section>
      <section className='action-group'>
        <Button
          buttonType='outlineBlueDark'
          disabled={isLoadingSubmit}
          onClick={() => {
            navigate(-1)
            setAction(false)
          }}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Cancelar'}
        </Button>
        <Button
          onClick={() => openModalConfirmation(true)}
          disabled={isLoadingSubmit}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Guardar'}
        </Button>
        <Button
          buttonType='blueDark'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => openModalConfirmation(false)}
        >
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
          {!isLoadingSubmit && 'Enviar'}
        </Button>
      </section>
      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setLateralBar={setOpenCommentBar}
        />
      )}
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          isLoadingSubmit={isLoadingSubmit}
          messages={modalConfig}
          handleSave={() => {
            if (isSavingFiles) {
              saveDocuments()
            } else {
              createProposal()
            }
          }}
        />
      )}
    </DocumentsUploadStyled>
  )
}

const DocumentsUploadStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;

  .loading span {
    background-color: white;
  }

  h1 {
    font-size: 18pt;
  }

  h2 {
    font-size: 14pt;
    margin-top: 25px;
  }

  .action-group {
    margin: 2rem 42px 0;
    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }

  .button-section {
    margin-top: ${({ isMargin }) => (isMargin ? '0' : '2.5rem')};
  }

  .customer-documents {
    .center-loading {
      height: 50vh;
      display: flex;
      align-items: center;
      div {
        width: 150px;
      }
    }

    section {
      width: 65%;
    }
  }
`

export default UploadProposal
