import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'
import { AVAILABLE_CURRENCIES } from 'utils/constants'

export const DEBT_CAPACITY_FORM = [
  {
    label: 'Moneda',
    name: 'currency',
    inputType: INPUT_TYPES.SELECT,
    options: AVAILABLE_CURRENCIES.map((currencyCode) => ({
      value: currencyCode,
      label: currencyCode,
    })),
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Monto',
    placeholder: 'Campo obligatorio*',
    name: 'amount',
    validatorType: VALIDATOR_TYPES.CURRENCY,
    errorMessage: 'Ingresa un monto valido',
    validatorParams: { min: 1, max: 9999999999.99 },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    label: 'Plazo máximo',
    placeholder: 'Campo obligatorio*',
    name: 'maxTerm',
    validatorType: VALIDATOR_TYPES.INTEGER_NUMBER,
    errorMessage: 'Ingresa un plazo válido',
    validatorParams: { min: 1, max: 9999 },
  },
]

/**
 * @const {Array<{id:string, name:string }>}
 */
export const FUNDERS = process.env.REACT_APP_FUNDERS
  ? JSON.parse(atob(process.env.REACT_APP_FUNDERS))
  : []

/**
 * @const {Array<{id: string, name: string, useI2C: boolean}>}
 */
export const PRODUCTS = process.env.REACT_APP_PRODUCTS
  ? JSON.parse(atob(process.env.REACT_APP_PRODUCTS))
  : []

/**
 * @const {Array<{id: string, name: string, productId: string}>}
 */
export const PAYMENT_METHODS = process.env.REACT_APP_PAYMENT_METHODS
  ? JSON.parse(atob(process.env.REACT_APP_PAYMENT_METHODS))
  : []
