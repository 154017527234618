import { Button, Icon } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import styled from 'styled-components'
import { AVAILABLE_CURRENCIES } from 'utils/constants'
import { DEBT_CAPACITY_FORM } from 'utils/schemas/credit-line'
import { v4 as UUIDv4 } from 'uuid'

const DebtCapacityForm = ({
  debtCapacities = [],
  setDebtCapacities,
  formErrors = [],
  setFormErrors,
  isValidating,
  allowReview = false,
}) => {
  const addPropose = () => {
    setDebtCapacities([
      ...debtCapacities,
      {
        id: UUIDv4(),
        currency: '',
        amount: '',
        status: 'APPROVED',
        maxTerm: 0,
        allowEdit: true,
      },
    ])
    setFormErrors([
      ...formErrors,
      { currency: true, amount: true, status: false, maxTerm: true },
    ])
  }

  const removePropose = (index) => {
    setDebtCapacities(debtCapacities.filter((_, i) => i !== index))
    setFormErrors(formErrors.filter((_, i) => i !== index))
  }

  const setDebtCapacity = (index, newDebtCapacity) => {
    const newFormValues = [...debtCapacities]
    newFormValues[index] = newDebtCapacity
    setDebtCapacities(newFormValues)
  }

  return (
    <Styles className='credit-line'>
      {debtCapacities.map((debtCapacity, index) => {
        const currencyIndex = DEBT_CAPACITY_FORM.findIndex(
          (field) => field.name === 'currency'
        )

        return (
          <article key={`${debtCapacity.id}-${index}`}>
            <Form
              schema={DEBT_CAPACITY_FORM.map((field, fieldIndex) => ({
                ...field,
                disabled:
                  fieldIndex === currencyIndex && !debtCapacity.allowEdit,
              }))}
              formValues={debtCapacity}
              setFormValues={(values) => {
                setDebtCapacity(index, { ...values })
              }}
              formErrors={formErrors[index]}
              setFormErrors={(errors) => {
                const newFormErrors = [...formErrors]
                newFormErrors[index] = errors
                setFormErrors(newFormErrors)
              }}
              isValidating={isValidating}
            />

            <section className='actions'>
              {!allowReview && debtCapacities.length > 1 && (
                <Button
                  className='remove-button'
                  buttonType='blueDark'
                  onClick={() => removePropose(index)}
                >
                  <Icon name='delete' />
                </Button>
              )}
              {allowReview && (
                <>
                  <Button
                    className={`danger ${
                      debtCapacity.status === 'REJECTED' ? 'checked' : ''
                    }`}
                    type='action'
                    buttonType='outline'
                    onClick={() =>
                      setDebtCapacity(index, {
                        ...debtCapacity,
                        status: 'REJECTED',
                      })
                    }
                  >
                    <Icon name='cancel' type='outlined' />
                  </Button>

                  <Button
                    className={`success ${
                      debtCapacity.status === 'APPROVED' ? 'checked' : ''
                    }`}
                    type='action'
                    buttonType='outline'
                    onClick={() =>
                      setDebtCapacity(index, {
                        ...debtCapacity,
                        status: 'APPROVED',
                      })
                    }
                  >
                    <Icon name='check_circle' type='outlined' />
                  </Button>
                </>
              )}
            </section>
          </article>
        )
      })}

      {debtCapacities.length < AVAILABLE_CURRENCIES.length && (
        <Button className='add-proposal' onClick={() => addPropose()}>
          <span>Añadir propuesta</span>
          <Icon name='add' />
        </Button>
      )}
    </Styles>
  )
}

const Styles = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 15px;

  article {
    display: grid;
    grid-template-columns: 100px repeat(2, 200px) 1fr;
    align-items: center;
    gap: 15px;
  }

  .add-proposal {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: fit-content;
    height: fit-content;
    padding: 5px;

    span {
      display: none;
    }
    span + span {
      display: inline-block;
    }

    &:hover {
      span {
        display: inline-block;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      display: grid;
      place-items: center;

      width: 2.5rem;
      border-radius: 5px;

      &.success {
        background-color: transparent;
        color: #2ecc71;
        border-color: #2ecc71;
        &.checked {
          background-color: #2ecc71;
          color: white;
        }
      }

      &.danger {
        color: #ff0045;
        border-color: #ff0045;
        background-color: transparent;
        &.checked {
          background-color: #ff0045;
          color: white;
        }
      }
    }
  }
`

export default DebtCapacityForm
