import { Edit, ArrowBackIos } from '@mui/icons-material'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import DebtCapacityInformation from '../components/debt-capacity-information'
import DebtCapacityFormController from './debt-capacity-form.controller'

const DebtCapacityController = (props) => {
  const {
    debtCapacities = {},
    setDebtCapacities = () => {},
    debtCapacitiesError = {},
    setDebtCapacitiesError = () => {},
    allowEdit = false,
    allowReview = false,
  } = props
  const [isEditing, setIsEditing] = useState(false)

  return (
    <SectionStyles>
      {allowEdit && (
        <Button
          className='edit-action'
          variant='contained'
          startIcon={isEditing ? <ArrowBackIos /> : undefined}
          style={{ width: 'fit-content', backgroundColor: '#00172d' }}
          onClick={() => setIsEditing(!isEditing)}
        >
          {!isEditing ? <Edit /> : 'Regresar'}
        </Button>
      )}
      {!isEditing &&
        debtCapacities.map((debtCapacity, index) => (
          <DebtCapacityInformation
            key={`debtCapacity-${index}`}
            debtCapacity={debtCapacity}
          />
        ))}
      {isEditing && (
        <DebtCapacityFormController
          debtCapacities={debtCapacities}
          setDebtCapacities={setDebtCapacities}
          formErrors={debtCapacitiesError}
          setFormErrors={setDebtCapacitiesError}
          allowReview={allowReview}
          isValidating
        />
      )}
    </SectionStyles>
  )
}

const SectionStyles = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  margin-top: 15px;
  padding-inline: 50px;
  padding-block: 20px;

  background: #7516440a;
  width: min-content;
  min-width: 800px;
  border-radius: 10px;

  height: fit-content;

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
  }

  .edit-action {
    width: 2.5rem;
    border-radius: 5px;
  }
`

export default DebtCapacityController

DebtCapacityController.propTypes = {
  debtCapacities: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      isApproved: PropTypes.bool,
    })
  ).isRequired,

  allowEdit: PropTypes.bool,
  allowReview: PropTypes.bool,
  setProposal: PropTypes.func,
  setDebtCapacities: PropTypes.func,
  debtCapacitiesError: PropTypes.array,
  setDebtCapacitiesError: PropTypes.func,
}
