/* eslint-disable no-control-regex */
import { dateFormatFromIso, getCurrentTimeToInput } from './date'
import * as Currency from './handlers-currency'
import * as Percentage from './handle-percentage'

const validators = {
  email: (value) =>
    /^(?!.*@.*@)[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
      value
    ),
  numbers: (value) =>
    value !== '' &&
    value !== '-' &&
    value.trim() === value &&
    !isNaN(value) &&
    /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
  alphaNum: (value) =>
    /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  string: (value) =>
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  phone: (value) => /^[0-9]{10}$/.test(value),
  zipCode: (value) => value !== '' && /^[0-9]{5}$/.test(value),
  lada: (value) => value !== '' && /^[0-9]{1,3}$/.test(value),
  percentage: (value, { min = 0, max = 100 }) => {
    if (!Percentage.canBeFormatted(value)) return false
    const numericValue = Percentage.extractNumberPercentage(value)
    return min <= numericValue && numericValue <= max
  },
  any: (value) => Boolean(value),
  rfc: (value) => /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{13}$/.test(value),
  rfcMoralPerson: (value) =>
    /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{12}$/.test(value),
  numberInterior: (value) =>
    value === '' || /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1\s]{1,20}$/.test(value),
  numberExterior: (value) => value !== '' && value.length <= 100,
  alphaNumSpace: (value) =>
    /^[-_ a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  term: (value) => value !== '' && /^[0-9]{1,2}$/.test(value),
  stringSpace: (value) =>
    /^[-_ a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  stringSpaceOptional: (value) =>
    value === '' ||
    /^[-_ a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  specialSymbols: (value) => /^\S(.*\S)?$/.test(value),
  decimal: (value) =>
    value !== '' && /^(0|[1-9]\d{0,1})?(\.\d+)?(?<=\d)$/.test(value),
  positivesNumbers: (value) =>
    value !== '' && value >= 0 && value.trim() === value && !isNaN(value),
  integerNumbers: (value) => /^[1-9]\d*$/.test(value),
  pastDates: (value) =>
    value !== '' && value <= dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  pastDateTime: (value) => value !== '' && value <= getCurrentTimeToInput(),
  optionalNumber: function (value) {
    return this.numbers(value) || value === ''
  },
  alphaNumLength: (value, { min = 2, max = 100, mandatory = true }) =>
    new RegExp(`^[a-zA-Z0-9]{${min},${max}}$`).test(value) ||
    (!mandatory && value === ''),
  alphaLenght: (value, { min = 2, max = 100, mandatory = true }) =>
    new RegExp(`^[-_ a-zA-ZÀ-ÿ\u00f1\u00d1]{${min},${max}}$`).test(value) ||
    (!mandatory && value === ''),
  numberValidation: (value, { min = 0, max = Number.MAX_VALUE }) =>
    value !== '' &&
    Number(value) >= min &&
    Number(value) <= max &&
    value.trim() === value &&
    !isNaN(value),
  currency: (value, { min = 1, max = 9999999999.99 }) => {
    if (!Currency.canBeFormatted(value)) return false
    const number = Currency.extractNumber(value)
    return min <= number && number <= max
  },
  commaSeparatedNumbers: (value) => {
    if (value === '') return false
    const days = value.split(',').map(Number)
    return days.every((day, index, array) => {
      if (isNaN(day)) return false
      if (!Number.isInteger(day)) return false
      if (index === 0) return day > 0
      return array[index - 1] < day
    })
  },
}

/**
 * @param {string} value
 * @param {Object} obj
 * @param {string} obj.type
 * @param {Object} obj.params
 * @returns
 */
export const validateValue = (value, { type, params }) => {
  value ??= ''
  type ??= 'any'

  const validatorFunction = validators[type] ?? validators.any

  return (
    `${value}`.trim() === `${value}` &&
    validatorFunction(`${value}`, params ?? {})
  )
}
