import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CustomerActions from 'containers/customer-detail/customer-actions'
import Header from 'containers/customer-detail/header'
import DebtCapacityManager from 'modules/debt-capacity/views/debt-capacities'
import { CustomerContext } from 'providers/customer'
import { useContext, useEffect, useState } from 'react'
import { fetchCustomerInstances } from 'services/workflow'
import RecordPayment from 'templates/customer-detail/record-payment'
import HistoryManager from 'templates/customer-detail/tabs/history-manager'
import LineManager from 'templates/customer-detail/tabs/line-manager'
import RelationManager from 'templates/customer-detail/tabs/relation-manager'
import CustomerComment from 'templates/precustomer-detail/tabs/customer-comment'
import CustomerDocuments from 'templates/precustomer-detail/tabs/customer-documents'
import CustomerInformation from 'templates/precustomer-detail/tabs/customer-information'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { dependentRoles } from 'utils/schemas/roles'

export const CUSTOMER_TABS = [
  {
    label: 'Información',
    component: CustomerInformation,
    show: () => true,
  },
  {
    label: 'Documentos',
    component: CustomerDocuments,
    show: () => true,
  },
  {
    label: 'Historial',
    component: HistoryManager,
    show: () => true,
  },
  {
    label: 'Comentarios',
    component: CustomerComment,
    show: () => true,
  },
  {
    label: 'Capacidades de endeudamiento',
    component: DebtCapacityManager,
    show: ({ userRole }) => {
      const roles = [
        ...dependentRoles['bpm.mx-amex-b2b.ope-hd'],
        ...dependentRoles['bpm.mx-amex-b2b.rsk-hd'],
      ]
      return roles.includes(userRole)
    },
  },
  {
    label: 'Administración de líneas',
    component: LineManager,
    show: ({ userRole }) => {
      const roles = [
        ...dependentRoles['bpm.mx-amex-b2b.ope-hd'],
        ...dependentRoles['bpm.mx-amex-b2b.rsk-hd'],
      ]
      return roles.includes(userRole)
    },
  },
  {
    label: 'Administración de relaciones',
    component: RelationManager,
    show: ({ userRole }) => {
      const roles = [
        ...dependentRoles['bpm.mx-amex-b2b.ope-hd'],
        ...dependentRoles['bpm.mx-amex-b2b.rsk-hd'],
      ]
      return roles.includes(userRole)
    },
  },
  {
    label: 'Pagos',
    component: RecordPayment,
    show: ({ userRole }) => {
      const roles = [...dependentRoles['bpm.mx-amex-b2b.fin-an']]
      return roles.includes(userRole)
    },
  },
]

const filterTabs = (tabs = []) => {
  const [userRole] = getUserRoles()
  const flow = sessionStorage.getItem('dashboardService')
  return tabs.filter(({ show }) => show({ userRole, flow }))
}

const CustomerDetail = () => {
  const { user } = useAuth()
  const [userRole] = getUserRoles()
  const { customer, setCustomer } = useContext(CustomerContext)
  const [availableTabs, setAvailableTabs] = useState(filterTabs(CUSTOMER_TABS))
  const [currentTab, setCurrentTab] = useState(CUSTOMER_TABS[0])

  useEffect(() => {
    const fetchInstance = async () => {
      let customerInstance = await fetchCustomerInstances(
        customer.id,
        customer.service
      )
      setCustomer({ ...customer, ...customerInstance })
    }

    if (customer && customer.id && !customer.customerInstance) {
      fetchInstance()
    }
  }, [customer, setCustomer])

  useEffect(() => {
    if (userRole) {
      const tabs = filterTabs(CUSTOMER_TABS, userRole)
      setAvailableTabs(tabs)
      setCurrentTab(tabs[0])
    }
  }, [userRole])

  if (!customer) {
    return <LoadingAnimation />
  }

  const Tab = currentTab?.component

  return (
    <>
      <CustomerActions customer={customer} userRoles={user?.iam?.roles} />
      <Header
        customer={customer}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        headerOptions={availableTabs}
      />
      {Tab && <Tab setCurrentTab={setCurrentTab} />}
    </>
  )
}

export default CustomerDetail
