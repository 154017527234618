import { Button } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { ApiDebtCapacityRepository } from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { uploadDocuments } from 'services/documents'
import { financeFind } from 'services/lines'
import styled from 'styled-components'
import RejectReviewModal from 'templates/actions/committee-actions/reject-review-modal'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { currency } from 'utils/functions/formatters'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import Router from 'utils/router'
import { CREDIT_STUDY, CREDIT_STUDY_HEAD } from 'utils/schemas/customer'
import {
  COMMITTEE_SEND,
  FILE_STAGES,
  FILE_STATES,
  FINANCIAL_ANALYSIS,
  VISIT_AND_COMMITTEE_REGISTER,
} from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'
import DebtCapacity from '../../../containers/debt-capacity/debt-capacity'
import { FormValidationError } from 'utils/schemas/errors'

const FILE_STAGE_VISIT = { stage: FILE_STAGES.VISIT }
const FILE_STAGE_RISK = { stage: FILE_STAGES.RISK }
const FILE_STAGE_CREDIT = { stage: FILE_STAGES.CREDIT }

const disableSchema = (schema) => {
  return schema.map((itm) => ({ ...itm, disabled: true }))
}

const FORM_SCHEMAS = {
  SOURCE: disableSchema(CREDIT_STUDY_HEAD),
  FINANCIAL: disableSchema(CREDIT_STUDY),
}

const VisitAndCommittee = () => {
  const { setAlert } = useContext(AlertContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const navigate = useNavigate()
  const { user } = useAuth()
  const [userRole] = getUserRoles()

  const visitDocuments = useFetchDocuments(customer?.id, FILE_STAGE_VISIT)
  const legalRiskDocuments = useFetchDocuments(customer?.id, FILE_STAGE_RISK)
  const creditReviewDocuments = useFetchDocuments(
    customer?.id,
    FILE_STAGE_CREDIT
  )

  const [isLoading, setIsLoading] = useState(false)
  const [commentBarConfiguration, setCommentBarConfiguration] = useState()
  const [financeForm, setFinanceForm] = useState({})
  const [financialDocuments, setFinancialDocuments] = useState([])
  const [debtCapacities, setDebtCapacities] = useState({
    debtCapacities: [],
    proposalId: undefined,
  })
  const [modalConfiguration, setModalConfiguration] = useState()
  const [isValidating, setIsValidating] = useState(false)
  const [files, setFiles] = useState({})

  const currentWorkflowState = customer?.wfState
  const { rejectBuyer, approveBuyer } = ONBOARDING_STATES.COMMITTEE.events

  const openRejectModal = (action) => {
    let modalConfiguration = {
      title: 'Está rechazando la propuesta de negocio',
      message: `¿Estás seguro de rechazar la propuesta de negocio?
        Después de esta acción no podrá retomar el proceso`,
      requireReason: true,
      action: rejectDebtCapacity,
    }

    if (action === 'approve') {
      modalConfiguration = {
        title: 'Está aprobando la propuesta de negocio',
        message: `Por favor ingrese la razón por la cual
          está aprobando la propuesta de negocio`,
        requireReason: true,
        action: approveDebtCapacity,
        icon: 'check_circle',
      }
    }

    setModalConfiguration(modalConfiguration)
  }

  const uploadFileList = async () => {
    const [userRole] = getUserRoles()
    const response = await uploadDocuments(
      files,
      customer.id,
      user.uid,
      userRole
    )

    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('file-upload-error')
    }
    return response.map((request) => request.value)
  }

  const validateDocuments = () => {
    const documentsSchema = VISIT_AND_COMMITTEE_REGISTER
    if (documentsSchema.some(({ typeId }) => !files[typeId]?.length > 0)) {
      setAlert({
        label:
          'Por favor sube todos los documentos marcados en rojo y vuelve a intentar',
        title: 'Error',
        type: 'error',
      })
      throw new Error('DocumentsError')
    }
  }

  const rejectDebtCapacity = async (reasonMessage) => {
    try {
      setIsLoading(true)
      setIsValidating(true)

      validateDocuments()

      if (!reasonMessage) {
        throw new FormValidationError(
          `Por favor ingrese la razón por la cual
            está rechazando la propuesta de negocio`
        )
      }

      const multiUploadIds = await uploadFileList()

      const filesRejected = multiUploadIds.map((file) => ({
        id: file.id,
        status: FILE_STATES.REFUSED,
      }))

      await ApiBuyerRepository.doEvent(customer.id, rejectBuyer, {
        'fileList:multiUpdate': filesRejected,
        'customerId:status': customer.id,
        'wfState:status': 'BUYER_REJECTED',
        'status:status': 'Rejected',
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
        'id:proposal': debtCapacities?.proposalId,
        'comment:comment': reasonMessage,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': true,
        'uuid:comment': user?.uid,
        'role:comment': userRole,
      })

      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else if (error.message === 'DocumentsError') {
        setAlert({
          label: `Por favor sube todos los documentos
              marcados en rojo y vuelve a intentar`,
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const approveDebtCapacity = async (reasonMessage) => {
    try {
      setIsLoading(true)
      setIsValidating(true)

      validateDocuments()

      if (!reasonMessage) {
        throw new FormValidationError(
          `Por favor ingrese la razón por la cual
            está aprobando la propuesta de negocio`
        )
      }

      const multiUploadIds = await uploadFileList()

      const filesApproved = multiUploadIds.map((file) => ({
        id: file.id,
        status: file.status ?? FILE_STATES.DRAFT,
      }))

      await ApiBuyerRepository.doEvent(customer.id, approveBuyer, {
        'fileList:multiUpdate': filesApproved,
        'customerId:status': customer.id,
        'wfState:status': 'COMMITTEE_REVIEW',
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
        'id:proposal': debtCapacities?.proposalId,
        'comment:comment': reasonMessage,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': true,
        'uuid:comment': user?.uid,
        'role:comment': userRole,
      })

      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else if (error.message === 'DocumentsError') {
        setAlert({
          label: `Por favor sube todos los documentos
              marcados en rojo y vuelve a intentar`,
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const documents = [...legalRiskDocuments, ...creditReviewDocuments]
    const savedFiles = documents.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    const visit = visitDocuments.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    setFiles(visit)
    setFinancialDocuments(savedFiles)
  }, [legalRiskDocuments, creditReviewDocuments, visitDocuments])

  useEffect(() => {
    const fetchDebtCapacities = async () => {
      const { debtCapacities, proposalId } =
        await ApiDebtCapacityRepository.getProposalByBuyerId(customer?.id)
      setDebtCapacities({ debtCapacities, proposalId })
    }
    fetchDebtCapacities()
  }, [customer?.id, currentWorkflowState])

  useEffect(() => {
    const getFinanceOptions = async () => {
      const schemas = [...CREDIT_STUDY_HEAD, ...CREDIT_STUDY]
      const [finance] = await financeFind(customer.id)

      const formattedObject = schemas.reduce((acc, curr) => {
        const { name, validatorType } = curr
        let value = finance[name]
        if (validatorType === 'currency') {
          value = currency(value)
        }
        return { ...acc, [name]: value }
      }, {})

      setFinanceForm(formattedObject)
    }

    if (customer?.id) getFinanceOptions()
  }, [customer?.id])

  return (
    <Styles>
      <section className='visit-upload'>
        <BuildSection
          allowComment
          allowDelete
          allowUpdate
          isValidating={isValidating}
          files={files}
          setFiles={setFiles}
          title='Documentos de la visita y comité'
          schema={VISIT_AND_COMMITTEE_REGISTER}
          setCommentBarInfo={setCommentBarConfiguration}
        />
      </section>
      <section className='introduction'>
        <BuildSection
          allowComment
          allowUpload={false}
          files={financialDocuments}
          title={COMMITTEE_SEND.title}
          schema={COMMITTEE_SEND.documents}
          setCommentBarInfo={setCommentBarConfiguration}
        />

        <DebtCapacity debtCapacities={debtCapacities.debtCapacities} />
      </section>

      <h5>Indicadores financieros</h5>
      <section className='source-form'>
        <Form
          schema={FORM_SCHEMAS.SOURCE}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <section className='financial-form'>
        <Form
          schema={FORM_SCHEMAS.FINANCIAL}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <BuildSection
        className='financial-documents'
        allowComment
        allowUpload={false}
        files={financialDocuments}
        isValidating={false}
        schema={FINANCIAL_ANALYSIS.generalInformation.documents}
        setCommentBarInfo={setCommentBarConfiguration}
        title={FINANCIAL_ANALYSIS.generalInformation.title}
      />

      <section className='financial-actions'>
        <Button
          buttonType='outlineBlueDark'
          onClick={() => {
            navigate(-1)
            setAction(undefined)
          }}
        >
          Regresar
        </Button>

        <div>
          <Button
            disabled={isLoading}
            onClick={() => !isLoading && openRejectModal('reject')}
          >
            Rechazar
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isLoading}
            onClick={() => !isLoading && openRejectModal('approve')}
          >
            Aprobar
          </Button>
        </div>
      </section>

      {commentBarConfiguration && (
        <LateralBar
          fileId={commentBarConfiguration.fileId}
          documentName={commentBarConfiguration.fileName}
          setLateralBar={setCommentBarConfiguration}
        />
      )}
      {modalConfiguration && (
        <RejectReviewModal
          handleCloseModal={() => setModalConfiguration(undefined)}
          modalConfiguration={modalConfiguration}
          handleSubmit={modalConfiguration.action}
          isValidating={isValidating}
        />
      )}
    </Styles>
  )
}

const Styles = styled.main`
  margin-block: 20px;
  padding-inline: 30px;

  .introduction {
    margin-block: 20px;
  }

  .source-form,
  .financial-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    margin-block: 10px;
  }

  .financial-documents {
    max-width: 800px;
    margin-block: 30px;
  }

  .financial-actions {
    margin-block: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button + button {
      margin-inline-start: 10px;
    }
  }
`

export default VisitAndCommittee
