import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'
import { AVAILABLE_CURRENCIES, MEX_STATE } from 'utils/constants'
import { dateFormatFromIso } from 'utils/functions/date'
import { LINE_STATES } from './line'

export const CUSTOMER_STAGES = {
  prospecting: {
    id: 'prospecting',
    label: 'Prospección',
  },
  assessment: {
    id: 'assessment',
    label: 'Assessment',
  },
  instrumentation: {
    id: 'instrumentation',
    label: 'Instrumentación',
  },
  reassessment: {
    id: 'reassessment',
    label: 'Reassessment',
  },
  setup: {
    id: 'setup',
    label: 'Setup',
  },
}

export const CUSTOMER_STATES = {
  cust_prospectCreated: {
    label: 'Prospecto creado',
  },
  cust_infoProvided: {
    label: 'Documentación pendiente',
    actionName: 'Subir documentación',
    transition: {
      SEND_PREASSESMENT: {
        event: 'SEND_TO_EVALUATION',
        nextState: 'cust_inEvaluation',
        nextStage: 'preassessment',
      },
      SEND_ASSESMENT: {
        event: 'SEND_TO_EVALUATION',
        nextState: 'cust_inEvaluation',
        nextStage: 'assessment',
      },
    },
  },
  cust_prospectAssignation: {
    label: 'Prospecto creado en hubspot',
    actionName: 'Asignarme prospecto',
    transition: {
      SEND: {
        event: 'ASSIGN_COMMERCIAL',
        nextState: 'cust_pendingInfo',
      },
    },
  },
  cust_pendingInfo: {
    label: 'Pendiente de información',
    actionName: 'Completa la información',
    transition: {
      COMPLETE_INFORMATION: {
        event: 'COMPLETE_INFO',
        nextState: 'cust_infoProvided',
      },
    },
  },
  cust_inEvaluation: {
    label: 'En evaluación',
    actionName: 'Asignarme prospecto',
    transition: {
      SEND: {
        workflow: 'customer',
        event: 'SEND_TO_OPERATIONS',
        nextState: 'cust_inOperationReview',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
    },
  },
  cust_inOperationReview: {
    label: 'En revisión de operaciones',
    actionName: 'Aprobar documentación',
    transition: {
      SEND: {
        workflow: 'customer',
        event: 'VALIDATE_EVAL_DOSSIER',
        nextState: 'cust_dossierValidated',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      RETURN: {
        workflow: 'customer',
        event: 'REQUEST_CORRECTIONS',
        nextState: 'cust_correctionRequest',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
    },
  },
  cust_correctionRequest: {
    label: 'Solicitud de correcciones al expediente\t',
    actionName: 'Corregir expediente',
    transition: {
      SEND: {
        workflow: 'customer',
        event: 'SEND_TO_OPERATIONS',
        nextState: 'cust_inOperationReview',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
  },
  cust_dossierValidated: {
    label: 'Expediente validado',
    actionName: 'Subir estudio de crédito',
    transition: {
      SEND_TO_ASSIGNMENT: {
        event: 'SEND_TO_CREDIT_REVIEW',
        nextState: 'line_underwriterAssignment',
      },
      SEND_TO_CREDIT_REVIEW: {
        event: 'SEND_TO_CREDIT_REVIEW',
        nextState: 'line_creditReview',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      BLOCK: {
        event: 'BLOCK_BY_PLD',
        nextState: 'cust_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      REJECT: {
        event: 'REJECT_BY_BURO',
        nextState: 'cust_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      SEND_TO_LEGAL: {
        event: 'SEND_TO LEGAL_REASSESSMENT',
        nextState: 'cust_proposalAccepted',
      },
    },
  },
  line_underwriterAssignment: {
    label: 'Pendiente revisión de crédito',
    actionName: 'Asignarme prospecto',
    transition: {
      SEND: {
        event: 'REVIEW_CREDIT',
        nextState: 'line_creditReview',
        notificationReceiverRoles: [],
      },
    },
  },
  line_creditReview: {
    label: 'En revisión de crédito',
    actionName: 'Subir Análisis Financiero',
    transition: {
      SEND_PREASSESSMENT: {
        event: 'COMPLETE_PREEVALUATION',
        nextState: 'line_preEvaluationComplete',
        lineStatus: LINE_STATES.PREOFFER,
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      SEND_ASSESSMENT: {
        event: 'COMPLETE_EVALUATION',
        nextState: 'line_evaluationComplete',
        lineStatus: LINE_STATES.OFFER,
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
      SEND_ASSESSMENT_BY_PREASSESSMENT: {
        event: 'COMPLETE_EVAL_PREASSESMENT',
        nextState: 'line_evaluationComplete',
        lineStatus: LINE_STATES.OFFER,
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      SEND_REASSESSMENT: {
        event: 'COMPLETE_REASSESSMENT',
        nextState: 'line_evaluationComplete',
        lineStatus: LINE_STATES.REASSESSMENT,
        notificationReceiverRoles: [],
      },
      REJECT_REASSESSMENT: {
        event: 'REJECT_REASSESSMENT',
        nextState: 'cust_customerActive',
        notificationReceiverRoles: [],
      },
      REJECT_ASSESSMENT: {
        event: 'REJECT_EVALUATION',
        nextState: 'line_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
      REJECT_PREASSESSMENT: {
        event: 'REJECT_PREEVALUATION',
        nextState: 'line_rejected',
        notificationReceiverRoles: [],
      },
      REJECT: {
        event: 'REJECT_EVAL_PREASSESMENT',
        nextState: 'line_rejected',
        notificationReceiverRoles: [],
      },
    },
  },
  /*  line_preEvaluationComplete: {
    label: 'Pre-evaluación completa',
    actionName: 'Subir Respuesta de Cliente',
    transition: {
      SEND: {
        event: 'ACCEPT_PREEVAL_BY_CUSTOMER',
        nextState: 'cust_uploadingAssessment',
        nextStage: 'assessment'
      },
      REJECT: {
        event: 'REJECT_BY_CUSTOMER',
        nextState: 'line_rejected'
      }
    }
  }, */
  cust_uploadingAssessment: {
    label: 'En evaluación - Assessment',
    actionName: 'Subir documentación',
    transition: {
      SEND: {
        event: 'SEND_TO_EVALUATION',
        nextState: 'cust_inOperationReview',
      },
    },
  },
  line_evaluationComplete: {
    label: 'Evaluación completa',
    actionName: 'Subir Respuesta de Cliente',
    transition: {
      SEND: {
        event: 'ACCEPT_EVAL_BY_CUSTOMER',
        nextState: 'cust_proposalAccepted',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
      SEND_REASSESSMENT: {
        event: 'APPROVE_REASSESSMENT',
        nextState: 'line_approved',
      },
      REJECT: {
        event: 'REJECT_BY_CUSTOMER',
        nextState: 'line_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
    },
  },
  cust_proposalAccepted: {
    label: 'Propuesta aceptada',
    actionName: 'Subir Análisis Legal',
    transition: {
      SEND: {
        event: 'APPROVE_LEGAL',
        nextState: 'cust_legalReviewCompleted',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
      RETURN: {
        event: 'REQUEST_EXTRA_DOCS',
        nextState: 'cust_pendingExtraDocs',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [],
      },
      SEND_REASSESSMENT: {
        event: 'APPROVE_REASSESSMENT',
        nextState: 'cust_customerActive',
        notificationReceiverRoles: [],
        notificationReceiverRoles2: [],
      },
    },
  },
  cust_pendingExtraDocs: {
    label: 'Información adicional requerida',
    actionName: 'Subir documentos adicionales',
    transition: {
      SEND: {
        event: 'SEND_EXTRA_DOCS',
        nextState: 'cust_extraDocsProvided',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
  },
  cust_extraDocsProvided: {
    label: 'En revisión de información adicional',
    actionName: 'Aprobar documentos adicionales',
    transition: {
      SEND: {
        event: 'APPROVE_LEGAL',
        nextState: 'cust_legalReviewCompleted',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      REJECT: {
        event: 'REQUEST_EXTRA_DOCS',
        nextState: 'cust_pendingExtraDocs',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
      SEND_REASSESSMENT: {
        event: 'APPROVE_REASSESSMENT',
        nextState: 'cust_customerActive',
        notificationReceiverRoles: [],
      },
    },
  },
  cust_legalReviewCompleted: {
    label: 'Revisión legal completa',
    actionName: 'Subir documentos de visita',
    transition: {
      SEND: {
        event: 'SEND_VISIT_REGISTRY',
        nextState: 'cust_pendingVisitApprobation',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
  },
  cust_pendingVisitApprobation: {
    label: 'Aprobación de visita pendiente',
    actionName: 'Aprobar documentos de visita',
    transition: {
      SEND: {
        event: 'APPROVE_VISIT',
        nextState: 'cust_visitApproved',
        notificationReceiverRoles: [],
      },
      RETURN: {
        event: 'RETURN_VISIT_COMMERCIAL',
        nextState: 'cust_legalReviewCompleted',
        notificationReceiverRoles: [],
      },
      REJECT: {
        event: 'REJECT_VISIT_COMMERCIAL',
        nextState: 'cust_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
    },
  },
  cust_visitApproved: {
    label: 'Visita aprobada - Comercial',
    actionName: 'Aprobar documentos de visita',
    transition: {
      SEND: {
        event: 'SEND_TO_PRECOMMITTEE',
        nextState: 'line_sendingToCommittee',
        notificationReceiverRoles: [],
      },
      RETURN: {
        event: 'RETURN_VISIT_OPERATIONS',
        nextState: 'cust_legalReviewCompleted',
        notificationReceiverRoles: [],
      },
      REJECT: {
        event: 'REJECT_VISIT_OPERATIONS',
        nextState: 'cust_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
    },
  },
  line_sendingToCommittee: {
    label: 'Por enviar al comité',
    actionName: 'Enviar a comité',
    transition: {
      REJECT: {
        event: 'REJECT_PRECOMMITE',
        nextState: 'line_rejected',
      },
    },
  },
  line_minorCommittee: {
    label: 'En comité menor',
    actionName: 'Responder comité',
    transition: {
      REJECT: {
        event: 'REJECT_BY_COMMITTEE',
        nextState: 'line_committeeRejectedResult',
      },
    },
  },
  line_majorCommittee: {
    label: 'En comité principal',
    actionName: 'Responder comité',
    transition: {
      REJECT: {
        event: 'REJECT_BY_COMMITTEE',
        nextState: 'line_committeeRejectedResult',
      },
    },
  },
  line_committeeResult: {
    label: 'Comité finalizado',
    actionName: 'Ver Resultados de comité',
    transition: {
      SEND: {
        event: 'SEND_TO_INSTRUMENTATION',
        nextStage: 'instrumentation',
        nextState: 'line_instrumentation',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
    },
  },
  line_committeeRejectedResult: {
    label: 'Comité finalizado - Rechazado',
    actionName: 'Ver Resultados de comité',
    transition: {
      SEND: {
        event: 'REJECT_BY_COMMITTEE',
        nextState: 'cust_rejected',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
    },
  },
  line_instrumentation: {
    label: 'En proceso de instrumentación',
    actionName: 'Generar contrato',
    transition: {
      SEND: {
        event: 'GENERATE_CONTRACT',
        nextState: 'line_contractGenerated',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
    },
  },
  line_contractGenerated: {
    label: 'Contrato generado',
    actionName: 'Subir documentos de contrato',
    transition: {
      SEND: {
        event: 'SEND_SIGNED_DOCS',
        nextState: 'line_signedDocsProvided',
      },
    },
  },
  line_signedDocsProvided: {
    label: 'Docs. firmados enviados',
    actionName: 'Aprobar documentos de contrato',
    transition: {
      SEND: {
        event: 'APPROVE_SIGNED_DOCS',
        nextState: 'line_approved',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
      },
      SEND_KEO: {
        event: 'APPROVED_CONTRACT_KEO',
        nextState: 'line_approved',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.ope-hd',
          'bpm.mx-amex-b2b.ope-jr',
        ],
        notificationReceiverRoles2: [
          'bpm.mx-amex-b2b.rsk-vp',
          'bpm.mx-amex-b2b.rsk-hd',
          'bpm.mx-amex-b2b.rsk-jr',
        ],
      },
      REJECT: {
        event: 'REQUEST_CONTRACT_CORRECTIONS',
        nextState: 'line_contractCorrectionRequested',
        notificationReceiverRoles: [
          'bpm.mx-amex-b2b.com-hd',
          'bpm.mx-amex-b2b.com-ej',
        ],
      },
    },
  },
  line_contractCorrectionRequested: {
    label: 'Solicitud de correcciones al contrato',
    actionName: 'Corregir documentos de contrato',
    transition: {
      SEND: {
        event: 'SEND_SIGNED_DOCS',
        nextState: 'line_signedDocsProvided',
      },
    },
  },
  cust_customerReady: {
    label: 'Cliente operando',
    actionName: 'Reassessment',
  },
  line_approved: {
    label: 'Modificación de linea',
    actionName: 'Modificar linea',
    transition: {
      DECREASE_AMOUNT: {
        event: 'DECREASE_REQUEST',
        nextState: 'line_updateReview',
        nextStage: 'reassessment',
      },
      UPDATE_LINE: {
        event: 'UPDATE_REQUEST',
        nextState: 'line_updateReview',
        nextStage: 'reassessment',
      },
    },
  },
  line_updateReview: {
    label: 'Evaluación de propuesta',
    actionName: 'Evaluar propuesta',
    transition: {
      APPROVE_DECREASE: {
        proposalStatus: 'Approved',
        event: 'APPROVE_DECREASE_REQUEST',
        nextState: 'line_amountReassigment',
      },
      APPROVE_REQUEST: {
        proposalStatus: 'Approved',
        event: 'APPROVE_REQUEST',
        nextStage: 'setup',
        nextState: 'line_approved',
      },
      REJECT_REQUEST: {
        proposalStatus: 'Rejected',
        event: 'REJECT_REQUEST',
        nextStage: 'setup',
        nextState: 'line_approved',
      },
    },
  },
  line_amountReassigment: {
    label: 'Re-asignación de relaciones',
    actionName: 'Reasignar monto de relaciones',
    transition: {
      SEND_REASSIGNMENT: {
        event: 'SEND_REASSIGMENT',
        nextStage: 'setup',
        nextState: 'line_approved',
      },
    },
  },
  cust_rejected: {
    label: 'Prospecto / Cliente rechazado',
  },
  line_rejected: {
    label: 'Prospecto / Cliente - Linea rechazada',
  },
  line_proposalCreated: {
    label: 'Pendiente de revisión de crédito',
    actionName: 'Asignarme prospecto',
    transition: {
      SEND: {
        event: 'REVIEW_REASSESMENT',
        nextState: 'line_creditReview',
      },
    },
  },
  cust_reassesment: {
    label: 'Reassessment de documentación',
    actionName: 'Subir documentación',
    transition: {
      SEND: {
        event: 'SEND_TO_OPERATIONS',
        nextState: 'cust_inOperationReview',
      },
    },
  },
}

export const CUSTOMER_DB_STATUS = {
  PROSPECT: 'Prospect',
  REJECTED: 'Rejected',
  READY: 'Ready',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  REASSESSMENT: 'Reassessment',
}

export const SHAREHOLDER_TYPES = {
  PERSON: 'Person',
  BUSINESS: 'Business',
}

export const CUSTOMER_SUPPLIER_SOURCES = {
  KEO: 'Keo',
  CALLAO: 'Callao',
}

/* CUSTOMER FORMS */
export const SH_LOCATION = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'address',
    label: 'Calle',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'externalNumber',
    label: 'Número de exterior',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.NUMBER_EXTERIOR,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'internalNumber',
    label: 'Número interior',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.NUMBER_INTERIOR,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'zipCode',
    label: 'Código postal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ZIP_CODE,
    minLength: 5,
    maxLength: 5,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'colony',
    label: 'Colonia',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'municipality',
    label: 'Delegación o Municipio',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
    validatorParams: {
      max: 20,
    },
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'state',
    label: 'Estado',
    options: MEX_STATE,
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city',
    label: 'Ciudad / Población',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
]

export const SH_PERSON = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Nombre',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.STRING_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'firstSurname',
    label: 'Apellido paterno',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'secondSurname',
    label: 'Apellido materno',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
      mandatory: false,
    },
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'civilStatus',
    label: 'Estado civil',
    options: [
      { label: 'Soltero/a', value: 'Soltero/a' },
      { label: 'Casado/a', value: 'Casado/a' },
      { label: 'Viudo/a', value: 'Viudo/a' },
      { label: 'Divorciado/a', value: 'Divorciado/a' },
      {
        label: 'Separación en proceso judicial',
        value: 'Separacion En Proceso Judicial',
      },
      { label: 'Concubinato', value: 'Concubinato' },
    ],
  },
  {
    inputType: INPUT_TYPES.PERCENTAGE,
    name: 'sharePercentage',
    label: '% Accionario',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.PERCENTAGE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'tin',
    label: 'RFC',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.RFC,
    minLength: 13,
    maxLength: 13,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email',
    label: 'Correo electrónico ',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.EMAIL,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'areaCode',
    label: 'Código de país',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.LADA,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone',
    label: 'Teléfono',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.PHONE,
    minLength: 10,
    maxLength: 10,
  },
]
export const REGISTRY = [
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'typePaid',
    label: 'Tipo de pago',
    options: [
      { label: 'Sobre pago', value: 'Sobre pago' },
      { label: 'Pago voluntario', value: 'Pago voluntario' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'reference',
    label: 'Referencia',
    placeholder: '*Campo obligatorio',
    disabled: false,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'amountField',
    label: 'Monto',
    placeholder: '*Campo obligatorio',
    disabled: false,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'date',
    label: 'Fecha',
    type: 'date',
    disabled: false,
  },
]

export const MASSIVE_FORM = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'date',
    label: 'Fecha de carga',
    type: 'date',
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'hour',
    label: 'Hora de carga',
    options: [
      { label: '31: 3:15 pm', value: '31: 3:15 pm' },
      { label: '24: 4:15 pm', value: '24: 4:15 pm' },
      { label: '19: 5:15 pm', value: '19: 5:15 pm' },
    ],
  },
]

export const BUSINESS_PROPOSAL = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'score',
    label: 'Monto que solicita *',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.SCORE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'ordinaryTerm',
    label: 'Plazo ordinario A',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.TERM,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'extendedTerm',
    label: 'Plazo ordinario B',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.TERM,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'cashbackTerm',
    label: 'Plazo de bonificación',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.TERM,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'interestRate',
    label: 'Tasa de interés A *',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.DECIMAL,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'extendedTermRate',
    label: 'Tasa de interés B *',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.DECIMAL,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lateFeeRate',
    label: 'Tasa moratoria *',
    placeholder: 'Escribe números',
    validatorType: VALIDATOR_TYPES.DECIMAL,
  },
]

export const SH_AGENT = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Nombre Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.STRING_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'firstSurname',
    label: 'Apellido paterno Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'secondSurname',
    label: 'Apellido materno Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
      mandatory: false,
    },
  },
]

export const SH_BUSINESS = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Denominación o Razón Social',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
    validatorParams: {
      min: 1,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'tin',
    label: 'RFC',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.RFC_MORAL_PERSON,
    minLength: 12,
    maxLength: 12,
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'mainActivity',
    label: 'Actividad principal',
    options: [
      { label: 'ABARROTERA', value: 'ABARROTERA' },
      { label: 'AGRICULTURA', value: 'AGRICULTURA' },
      { label: 'ALIMENTARIA', value: 'ALIMENTARIA' },
      { label: 'AUTOMOVILISTICO', value: 'AUTOMOVILISTICO' },
      { label: 'BEBIDAS', value: 'BEBIDAS' },
      { label: 'BIENES RAICES', value: 'BIENES RAICES' },
      { label: 'COMBUSTIBLE', value: 'COMBUSTIBLE' },
      { label: 'COMERCIALIZADORA', value: 'COMERCIALIZADORA' },
      {
        label: 'COMERCIALIZADORA ALIMENTARIA',
        value: 'COMERCIALIZADORA ALIMENTARIA',
      },
      { label: 'CONSTRUCCION', value: 'CONSTRUCCION' },
      { label: 'DISTRIBUIDORA', value: 'DISTRIBUIDORA' },
      { label: 'ELÉCTRICA', value: 'ELÉCTRICA' },
      { label: 'EMPACADORA', value: 'EMPACADORA' },
      { label: 'ENERGÍAS RENOVABLES', value: 'ENERGÍAS RENOVABLES' },
      { label: 'FABRICACION DE INSUMOS', value: 'FABRICACION DE INSUMOS' },
      { label: 'FARMACEUTICA', value: 'FARMACEUTICA' },
      { label: 'GANADERIA', value: 'GANADERIA' },
      { label: 'HIDROCARBUROS', value: 'HIDROCARBUROS' },
      { label: 'HOTELERIA', value: 'HOTELERIA' },
      { label: 'IMPRESORA', value: 'IMPRESORA' },
      { label: 'JUGUETERIA', value: 'JUGUETERIA' },
      { label: 'LABORATORIO', value: 'LABORATORIO' },
      { label: 'LLANTAS', value: 'LLANTAS' },
      { label: 'LOGISTICA', value: 'LOGISTICA' },
      { label: 'MANUFACTURA ALIMENTARIA', value: 'MANUFACTURA ALIMENTARIA' },
      { label: 'MANUFACTURA EN GENERAL', value: 'MANUFACTURA EN GENERAL' },
      { label: 'MAQUINARIA', value: 'MAQUINARIA' },
      { label: 'MATERIALES DE RECICLAJE', value: 'MATERIALES DE RECICLAJE' },
      { label: 'METALURGIA', value: 'METALURGIA' },
      { label: 'PLASTICOS', value: 'PLASTICOS' },
      { label: 'PRODUCTOS QUIMICOS', value: 'PRODUCTOS QUIMICOS' },
      { label: 'REFACCIONES', value: 'REFACCIONES' },
      { label: 'SALUD', value: 'SALUD' },
      { label: 'SERVICIOS PROFESIONALES', value: 'SERVICIOS PROFESIONALES' },
      { label: 'SUMINISTROS GENERALES', value: 'SUMINISTROS GENERALES' },
      { label: 'TECNOLOGIA', value: 'TECNOLOGIA' },
      { label: 'TEXTIL', value: 'TEXTIL' },
      { label: 'TRANSPORTISTA', value: 'TRANSPORTISTA' },
      { label: 'TRANSPORTISTA DE CARGA', value: 'TRANSPORTISTA DE CARGA' },
      { label: 'TRANSPORTISTA MARITIMA', value: 'TRANSPORTISTA MARITIMA' },
      { label: 'TURISMO', value: 'TURISMO' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'areaCode',
    label: 'Código de país',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.LADA,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone',
    label: 'Teléfono',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.PHONE,
    minLength: 10,
    maxLength: 10,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email',
    label: 'Correo electrónico ',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.EMAIL,
  },
]

export const CUSTOMER_PROFILE = [
  // INPUT TYPE CURRENCY EXPAMPLE
  // {
  //   inputType: INPUT_TYPES.CURRENCY,
  //   name: 'leadSource:customer',
  //   label: 'TEST DINERO',
  //   placeholder: 'Example: 1,000.00 ',
  //   validatorType: VALIDATOR_TYPES.CURRENCY
  // },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'group:customer',
    label: 'Grupo',
    options: [
      { label: 'Matriz', value: 'Matriz' },
      { label: 'Subsidiaria', value: 'Subsidiaria' },
      { label: 'PYME', value: 'PYME' },
    ],
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'leadSource:customer',
    label: 'Lead source',
    options: [
      { label: 'Bróker', value: 'Bróker' },
      { label: 'Cámaras y Asociaciones', value: 'Cámaras y Asociaciones' },
      { label: 'Campaña E-mail Marketing', value: 'Campaña E-mail Marketing' },
      { label: 'Data Science Lead', value: 'Data Science Lead' },
      { label: 'GMS', value: 'GMS' },
      { label: 'GNS', value: 'GNS' },
      { label: 'Holcim', value: 'Holcim' },
      { label: 'Ingram', value: 'Ingram' },
      { label: 'Ingram - mkt', value: 'Ingram - mkt' },
      { label: 'KEO', value: 'KEO' },
      { label: 'One Page', value: 'One Page' },
      { label: 'Other', value: 'Other' },
      { label: 'PAGEBANK', value: 'PAGEBANK' },
      { label: 'Partner', value: 'Partner' },
      { label: 'Refiere y Gana', value: 'Refiere y Gana' },
      { label: 'Sales Navigator', value: 'Sales Navigator' },
      { label: 'Strategic BI', value: 'Strategic BI' },
      { label: 'UNIFIN', value: 'UNIFIN' },
      { label: 'Web', value: 'Web' },
    ],
    validatorType: VALIDATOR_TYPES.ANY,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'satIndustriesFilter',
    label: 'Búsqueda de industria',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ANY,
  },
]

export const CREATE_LINE_FORM = {
  FORM_ONE: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'interestA',
      label: 'Tasa de interés A',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.DECIMAL,
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'interestB',
      label: 'Tasa de interés B',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.DECIMAL,
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'defaultRate',
      label: 'Tasa moratoria',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.DECIMAL,
    },
  ],
  FORM_TWO: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'ordinaryTerm',
      label: 'Plazo ordinario A',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.TERM,
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'extensionTerm',
      label: 'Plazo ordinario B',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.TERM,
    },
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'cashback',
      label: 'Plazo de bonificación',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.TERM,
    },
  ],
  FORM_THREE: [
    {
      inputType: INPUT_TYPES.TEXT_FIELD,
      name: 'score',
      label: 'Monto',
      placeholder: 'Escribe números',
      validatorType: VALIDATOR_TYPES.POSITIVE_NUMBER,
    },
  ],
}

export const CUSTOMER_FORM = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name:customer',
    label: 'Denominación o Razón social',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ANY,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'nid:customer',
    label: 'RFC',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.RFC_MORAL_PERSON,
    minLength: 12,
    maxLength: 12,
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'mainActivity:customer',
    label: 'Actividad principal',
    options: [
      { label: 'ABARROTERA', value: 'ABARROTERA' },
      { label: 'AGRICULTURA', value: 'AGRICULTURA' },
      { label: 'ALIMENTARIA', value: 'ALIMENTARIA' },
      { label: 'AUTOMOVILISTICO', value: 'AUTOMOVILISTICO' },
      { label: 'BEBIDAS', value: 'BEBIDAS' },
      { label: 'BIENES RAICES', value: 'BIENES RAICES' },
      { label: 'COMBUSTIBLE', value: 'COMBUSTIBLE' },
      { label: 'COMERCIALIZADORA', value: 'COMERCIALIZADORA' },
      {
        label: 'COMERCIALIZADORA ALIMENTARIA',
        value: 'COMERCIALIZADORA ALIMENTARIA',
      },
      { label: 'CONSTRUCCION', value: 'CONSTRUCCION' },
      { label: 'DISTRIBUIDORA', value: 'DISTRIBUIDORA' },
      { label: 'ELÉCTRICA', value: 'ELÉCTRICA' },
      { label: 'EMPACADORA', value: 'EMPACADORA' },
      { label: 'ENERGÍAS RENOVABLES', value: 'ENERGÍAS RENOVABLES' },
      { label: 'FABRICACION DE INSUMOS', value: 'FABRICACION DE INSUMOS' },
      { label: 'FARMACEUTICA', value: 'FARMACEUTICA' },
      { label: 'GANADERIA', value: 'GANADERIA' },
      { label: 'HIDROCARBUROS', value: 'HIDROCARBUROS' },
      { label: 'HOTELERIA', value: 'HOTELERIA' },
      { label: 'IMPRESORA', value: 'IMPRESORA' },
      { label: 'JUGUETERIA', value: 'JUGUETERIA' },
      { label: 'LABORATORIO', value: 'LABORATORIO' },
      { label: 'LLANTAS', value: 'LLANTAS' },
      { label: 'LOGISTICA', value: 'LOGISTICA' },
      { label: 'MANUFACTURA ALIMENTARIA', value: 'MANUFACTURA ALIMENTARIA' },
      { label: 'MANUFACTURA EN GENERAL', value: 'MANUFACTURA EN GENERAL' },
      { label: 'MAQUINARIA', value: 'MAQUINARIA' },
      { label: 'MATERIALES DE RECICLAJE', value: 'MATERIALES DE RECICLAJE' },
      { label: 'METALURGIA', value: 'METALURGIA' },
      { label: 'PLASTICOS', value: 'PLASTICOS' },
      { label: 'PRODUCTOS QUIMICOS', value: 'PRODUCTOS QUIMICOS' },
      { label: 'REFACCIONES', value: 'REFACCIONES' },
      { label: 'SALUD', value: 'SALUD' },
      { label: 'SERVICIOS PROFESIONALES', value: 'SERVICIOS PROFESIONALES' },
      { label: 'SUMINISTROS GENERALES', value: 'SUMINISTROS GENERALES' },
      { label: 'TECNOLOGIA', value: 'TECNOLOGIA' },
      { label: 'TEXTIL', value: 'TEXTIL' },
      { label: 'TRANSPORTISTA', value: 'TRANSPORTISTA' },
      { label: 'TRANSPORTISTA DE CARGA', value: 'TRANSPORTISTA DE CARGA' },
      { label: 'TRANSPORTISTA MARITIMA', value: 'TRANSPORTISTA MARITIMA' },
      { label: 'TURISMO', value: 'TURISMO' },
    ],
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'address:location',
    label: 'Calle',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'externalNumber:location',
    label: 'Número de exterior',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.NUMBER_EXTERIOR,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'internalNumber:location',
    label: 'Número interior',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.NUMBER_INTERIOR,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'zipCode:location',
    label: 'Código postal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ZIP_CODE,
    minLength: 5,
    maxLength: 5,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'colony:location',
    label: 'Colonia',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'municipality:location',
    label: 'Delegación o Municipio',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
    validatorParams: {
      max: 20,
    },
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'state:location',
    label: 'Estado',
    options: MEX_STATE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city:location',
    label: 'Ciudad / Población',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
]

export const CUSTOMER_AGENT = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name:agent',
    label: 'Nombre Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.STRING_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'firstSurname:agent',
    label: 'Apellido paterno Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'secondSurname:agent',
    label: 'Apellido materno Representante Legal',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
      mandatory: false,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'areaCode:agent',
    label: 'Código de país',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.LADA,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone:agent',
    label: 'Teléfono',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.PHONE,
    minLength: 10,
    maxLength: 10,
  },
]

export const CUSTOMER_USER = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name:user',
    label: 'Nombre del Contacto Comercial',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHANUMERIC_SPACE,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'firstSurname:user',
    label: 'Apellido paterno',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'secondSurname:user',
    label: 'Apellido materno',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.ALPHA_LENGTH,
    validatorParams: {
      min: 3,
      max: 35,
      mandatory: false,
    },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'areaCode:user',
    label: 'Código de país',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.LADA,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone:user',
    label: 'Teléfono',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.PHONE,
    minLength: 10,
    maxLength: 10,
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email:user',
    label: 'Correo electrónico',
    placeholder: 'Ejemplo: xxxxxx',
    validatorType: VALIDATOR_TYPES.EMAIL,
  },
]

export const CREDIT_STUDY_HEAD = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'cutoffDate',
    label: 'Fecha corte información financiera',
    type: 'date',
    validatorType: VALIDATOR_TYPES.PAST_DATES,
    errorMessage: 'Escribe una fecha válida',
    max: dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'source',
    label: 'Fuente de datos',
    placeholder: 'xxxxxxxx',
    validatorType: VALIDATOR_TYPES.STRING_SPACE,
  },
]

export const CREDIT_STUDY = [
  {
    label: 'Tipo de moneda',
    name: 'currency',
    inputType: INPUT_TYPES.SELECT,
    options: AVAILABLE_CURRENCIES.map((currencyCode) => ({
      value: currencyCode,
      label: currencyCode,
    })),
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'patrimony',
    label: 'Patrimonio',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'assets',
    label: 'Activos',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'grossMargin',
    label: 'Margen bruto de utilidad',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'utility',
    label: 'Utilidad',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'annualSales',
    label: 'Ventas anuales',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'accountsReceivable',
    label: 'Cuentas por cobrar',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'financialDebt',
    label: 'Deuda financiera',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    name: 'debsToPay',
    label: 'Cuentas por pagar',
    placeholder: 'xx,xxx,xxx.xx',
    validatorType: VALIDATOR_TYPES.CURRENCY,
  },
]

export const EVENTS = {
  SEND_EVAL_TO_OPERATIONS: 'Assessment enviado a operaciones',
  SEND_PREEVAL_TO_OPERATIONS: 'Pressessment enviado a operaciones',
  SEND_TO_OPERATIONS_REVIEW: 'Revisión de operaciones',
  REQUEST_CORRECTIONS: 'Solicitar correcciones al expediente',
  VALIDATE_PREEVAL_DOSSIER: 'Validar pre-propuesta',
  VALIDATE_EVAL_DOSSIER: 'Validar propuesta',
  BLOCK_BY_PLD: 'Bloqueado por PLD',
  REJECT_BY_BURO: 'Rechazado por Buró',
  SEND_TO_CREDIT_REVIEW: 'Enviar a revisión de crédito',
  REJECT_EVALUATION: 'Rechazar propuesta',
  REJECT_PREEVALUATION: 'Rechazar pre-propuesta',
  COMPLETE_EVALUATION: 'Completar propuesta',
  COMPLETE_PREEVALUATION: 'Completar pre-propuesta',
  REJECT_BY_CUSTOMER: 'Propuesta Rechazada por cliente',
  ACCEPT_EVAL_BY_CUSTOMER: 'Propuesta aceptada por cliente',
  ACCEPT_PREEVAL_BY_CUSTOMER: 'Aceptar pre-propuesta por cliente',
  REQUEST_EXTRA_DOCS: 'Solicitar información adicional',
  APPROVE_LEGAL: 'Completar revisión legal',
  SEND_EXTRA_DOCS: 'Subir doc. legal adicional',
  REQUEST_CORRECTIONS_EXTRA_DOCS: 'Esperar doc. legal adicional ',
  ACCEPT_EXTRA_DOCS: 'Revisión legal completa',
  SEND_VISIT_REGISTRY: 'Aprobación de visita',
  REJECT_VISIT_COMMERCIAL: 'Rechazo por visita por comercial',
  RETURN_VISIT_COMMERCIAL: 'Vista devuelta por comercial',
  APPROVE_VISIT: 'Visita aprobada - Comercial',
  REJECT_VISIT_OPERATIONS: 'Rechazo por visita por operaciones',
  RETURN_VISIT_OPERATIONS: 'Vista devuelta por operaciones',
  SEND_TO_PRECOMMITTEE: 'Enviar a pre-comité',
  REJECT_PRECOMMITTEE: 'Rechazo por pre-comité ',
  SEND_MAJOR_COMMITTEE: 'Enviar a comité mayor',
  SEND_MINOR_COMMITTEE: 'Enviar a comité menor',
  REJECT_BY_COMMITTEE: 'Rechazo por Comité',
  APPROVED_BY_COMMITTEE: 'Instrumentación',
  GENERATE_CONTRACT: 'Generar contrato',
  SEND_SIGNED_DOCS: 'Enviar docs. firmados',
  REQUEST_CONTRACT_CORRECTIONS: 'Solicitar correcciones al contrato',
  APPROVE_SIGNED_DOCS: 'Aprobar docs. firmados',
  SEND_TO_PREEVALUATION: 'Prospecto Enviado a preevaluación',
  SEND_TO_OPERATIONS: 'Documentos enviados a operaciones',
  REVIEW_CREDIT: 'Enviado a revisión de crédito',
  COMPLETE_EVAL_PREASSESMENT: 'Preassessment completo',
  SEND_TO_EVALUATION: 'Enviado a evaluación',
  SEND_MINOR_COMMITE: 'Enviado a comité menor',
  REJECT_PRECOMMITE: 'Rechazado antes de comité',
  SEND_MAJOR_COMMITE: 'Enviado a comité mayor',
  SEND_TO_INSTRUMENTATION: 'Enviado a instrumentación',
  SEND_TO_LEGAL_REVIEW: 'Enviado a revisión legal',
  SEND_TO_REASSESSMENT: 'Enviado a Reassessment',
  APPROVE_REASSESSMENT: 'Reassessment aprobado',
  'SEND_TO LEGAL_REASSESSMENT': 'Enviado a revisión legal en Reassessment',
  REQUEST_FIRST_LOAN: 'Solicitar primer préstamo',
  APPROVE_REQUEST: 'Aprobar solicitud',
  COMPLETE_INFO: 'Información completa',
  FREEZE_CUSTOMER: 'Cliente congelado',
  UPDATE_SCORE_REQUEST: 'Solicitud de actualización de puntaje',
  UNFREEZE_CUSTOMER: 'Descongelar cliente',
  ASSIGN_COMMERCIAL: 'Asignar comercial',
  CREATED_BY_HUBSPOT: 'Creado por HubSpot',
  CREATED_BY_KEO: 'Creado por Keo',
  APPROVE_REASSESMENT: 'Aprobar reevaluación',
  SEND_REASSIGMENT: 'Enviar reasignación',
  APPROVE_DECREASE_REQUEST: 'Aprobar solicitud de disminución',
  REJECT_REQUEST: 'Solicitud de rechazo',
  UPDATE_REQUEST: 'Solicitud de actualización',
  DECREASE_REQUEST: 'Solicitud de disminución',
  COMPLETE_REASSESSMENT: 'Reevaluación completa',
  REJECT_EVAL_PREASSESMENT: 'Rechazar preevaluación de evaluación',
  REJECT_REASSESSMENT: 'Rechazar reevaluación',
  REVIEW_REASSESMENT: 'Reevaluación de la reevaluación',
  SEND_TO_CREDIT_REASSESSMENT: 'Enviar a reevaluación de crédito',
  APPROVED_CONTRACT_KEO: 'Contrato keo aprobado',
}

export const initialCustomerForm = {
  personType: 'naturalPerson',
  customer: {
    personType: 'naturalPerson',
    'leadSource:date': dateFormatFromIso(undefined, 'yyyy-MM-dd'),
  },
  sh_location: {},
  sh_person: {},
  sh_agent: {},
  sh_business: {},
}

export const CUSTOMER_SUPPLIER_SOURCE_OPTIONS = [
  {
    value: CUSTOMER_SUPPLIER_SOURCES.KEO,
    label: 'Keo',
  },
  {
    value: CUSTOMER_SUPPLIER_SOURCES.CALLAO,
    label: 'Callao',
  },
]
