import ModalUpload from 'containers/Modal/modal-documents-upload'
import LineConditions, {
  initialLineConditionsForm as initialForm,
  verifyErrors,
} from 'containers/line-conditions/line-conditions'
import { AlertContext } from 'providers/alert'
import * as Percentage from 'utils/functions/handle-percentage'
import * as Currency from 'utils/functions/handlers-currency'
import { ApiError, FormValidationError } from 'utils/schemas/errors'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useState } from 'react'
import { ApiLineRepository } from 'services/line.repository'
import styled from 'styled-components'

const ERRORS = {
  LINE_CONDITIONS: {
    key: 'LINE_CONDITIONS',
    message: `Complete el formulario con las condiciones de la linea`,
  },
  MAX_SCORE: {
    key: 'MAX_SCORE',
    message: `El monto de linea ingresado no está disponible,
        el valor supera a la capacidad de endeudamiento disponible`,
  },
}

const EditLine = (props) => {
  const { line, setLateralBar, callback } = props
  const [gaveUpSelector, setGaveUpSelector] = useState(false)
  const [lineForm, setLineForm] = useState(initialForm)
  const [lineFormErrors, setLineFormErrors] = useState(initialForm)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { setAlert } = useContext(AlertContext)

  const validateFormErrors = (lineFormErrors) => {
    const isThereError = verifyErrors(lineFormErrors)

    if (isThereError) {
      setOpenModalUpload(false)
      throw new FormValidationError(
        ERRORS.LINE_CONDITIONS.message,
        ERRORS.LINE_CONDITIONS.key
      )
    }
  }

  const onHandleUpdate = async () => {
    try {
      setIsValidating(true)
      setIsLoadingSubmit(true)

      validateFormErrors(lineFormErrors)

      const {
        line: lineConditions,
        ordinary,
        moratorium,
        commission,
      } = lineForm

      if (gaveUpSelector !== line.gaveUp) {
        await ApiLineRepository.assignToTrust(line.externalId, gaveUpSelector)
      }

      const requestBody = {
        id: line.externalId,
        amount: Currency.extractNumber(lineConditions.score),
        maxTerm: Number(lineConditions.maxTerm),
        terms: [
          ...ordinary.map((period) => {
            return {
              name: period.name,
              rate: Percentage.extractNumberPercentage(period.rate),
              term: Number(period.term),
              typeId: Number(period.typeId),
              isDelinquency: false,
            }
          }),
          ...moratorium.map((period) => {
            return {
              name: period.name,
              rate: Percentage.extractNumberPercentage(period.rate),
              typeId: Number(period.typeId),
              isDelinquency: true,
            }
          }),
        ],
        commissions: commission.map((commission) => {
          return {
            name: commission.name,
            amount: Currency.extractNumber(commission.amount),
            days: commission.days.split(',').map(Number),
          }
        }),
      }

      const { data } = await ApiLineRepository.updateConditions(
        line.externalId,
        requestBody
      )

      const { action, amount } = data.i2cInfo

      if (action === 'increase') {
        await ApiLineRepository.increaseAmount(
          line.externalId,
          data.crn,
          amount
        )
      } else if (action === 'decrease') {
        await ApiLineRepository.decreaseAmount(
          line.externalId,
          data.crn,
          amount
        )
      }

      setAlert({
        type: 'success',
        title: '¡Línea actualizada!',
        label: `La línea ${line.lineId}
          ha sido actualizada con éxito`,
      })
      setLateralBar(undefined)
    } catch (error) {
      if (error instanceof FormValidationError) {
        return setAlert({
          type: 'error',
          title: '¡Ups! Algo salió mal',
          label: error.message,
        })
      }

      if (error instanceof ApiError) {
        return setAlert({
          type: error.statusCode === 400 ? 'warning' : 'error',
          title: '¡Ups! ha ocurrido un error',
          label: error.message,
        })
      }

      setAlert({
        type: 'error',
        title: '¡Ups! Algo salió mal',
        label: `No se pudo actualizar la línea,
          por favor contacte a soporte`,
      })
    } finally {
      callback()
      setIsLoadingSubmit(false)
      setOpenModalUpload(false)
    }
  }

  useEffect(() => {
    if (line) {
      const { commissions, maxTerm, score, terms, currency } = line
      const format = {
        line: {
          score: Currency.formatCurrency(score),
          maxTerm: maxTerm,
          currency: currency ?? 'MXN',
        },
        ordinary: terms
          .filter((period) => !period.delinquency)
          .map((item) => {
            const { rate } = item ?? {}
            return {
              ...item,
              rate: Percentage.formatPercentage(rate),
            }
          }),
        moratorium: terms
          .filter((period) => period.delinquency)
          .map((item) => {
            const { rate } = item ?? {}
            return {
              ...item,
              rate: Percentage.formatPercentage(rate),
            }
          }),
        commission: commissions.map((commission) => {
          return {
            ...commission,
            amount: Currency.formatCurrency(commission.amount),
            days: commission.days?.join(',') ?? '',
          }
        }),
      }
      setLineForm(format)
      setGaveUpSelector(line.gaveUp)
    }
  }, [line])

  useEffect(() => {
    if (!line) return

    const verifyValue = (object) => {
      return Object.entries(object).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value === undefined }
      }, {})
    }

    const setErrors = () => {
      const { commissions, maxTerm, score, terms } = line
      const errorFormat = {
        line: {
          score: !score,
          maxTerm: !maxTerm,
          currency: false,
        },
        ordinary: terms
          .filter((period) => !period.delinquency)
          .map(verifyValue),
        moratorium: terms
          .filter((period) => period.delinquency)
          .map(verifyValue),
        commission: commissions.map((commission) => {
          return verifyValue({
            ...commission,
            days: commission.days?.join(',') ?? '',
          })
        }),
      }
      setLineFormErrors(errorFormat)
    }

    const id = setTimeout(() => {
      setErrors()
    }, 250)

    return () => clearTimeout(id)
  }, [line])

  return (
    <Styles>
      <div className='scroll'>
        <h3>Editar Línea {line.lineId}</h3>
        <LineConditions
          withCurrency={false}
          formErrors={lineFormErrors}
          isValidating={isValidating}
          lineForm={lineForm}
          setFormErrors={setLineFormErrors}
          setLineForm={setLineForm}
        />
        <br />
        <h5 className='p-title'>Cedido a fideicomiso</h5>
        <label className='switch-div'>
          <p>No </p>
          <label className='switch'>
            <input
              type='checkbox'
              checked={gaveUpSelector}
              onChange={() => setGaveUpSelector((prev) => !prev)}
            />
            <span className='slider round' />
          </label>
          <p> Sí</p>
        </label>
        <div className='buttons'>
          <Button
            disabled={isLoadingSubmit}
            buttonType='outline'
            onClick={() => setLateralBar(undefined)}
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoadingSubmit}
            buttonType='blueDark'
            onClick={setOpenModalUpload}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Actualizar'}
          </Button>
        </div>
        {openModalUpload && (
          <ModalUpload
            setOpenModalUpload={setOpenModalUpload}
            messages={{
              msg: '¿Estás seguro que deseas  modificar la línea?',
              msgButton: 'Confirmar',
            }}
            handleSave={onHandleUpdate}
            isLoadingSubmit={isLoadingSubmit}
          />
        )}
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  height: 90%;
  overflow-y: hidden;
  padding: 0rem 2rem;

  .scroll {
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .offer-content {
    button.action span.icon {
      margin: inherit;
    }
  }

  .buttons {
    display: flex;

    gap: 2rem;

    button {
      width: 155px;
      height: 34px;
    }
  }
`

export default EditLine
