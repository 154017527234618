import { Button, Icon } from '@keoworld/gbl-ui-kit'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import { currency } from 'utils/functions/formatters'
import { extractNumber } from 'utils/functions/handlers-currency'
import DebtCapacityForm from './debt-capacity-form'

const DebtCapacity = (props) => {
  const {
    debtCapacities = {},
    setDebtCapacities = () => {},
    debtCapacitiesError = {},
    setDebtCapacitiesError = () => {},
    allowEdit = false,
    allowReview = false,
  } = props
  const [isEditing, setIsEditing] = useState(false)

  const setDebtCapacity = (debtCapacity) => {
    const index = debtCapacities.findIndex(
      ({ currency }) => currency === debtCapacity.currency
    )
    const newDebtCapacities = [...debtCapacities]
    newDebtCapacities[index] = debtCapacity
    setDebtCapacities(newDebtCapacities)
  }

  return (
    <SectionStyles>
      {allowEdit && (
        <Button
          className='edit-action'
          buttonType='blueDark'
          onClick={() => setIsEditing(!isEditing)}
        >
          <Icon name='edit' />
        </Button>
      )}
      {!isEditing &&
        debtCapacities.map((debtCapacity, index) => (
          <RowDebtCapacity
            key={`debtCapacity-${index}`}
            debtCapacity={debtCapacity}
            setDebtCapacity={setDebtCapacity}
            allowReview={allowReview}
          />
        ))}
      {isEditing && (
        <section className='debt-capacities-form'>
          <DebtCapacityForm
            debtCapacities={debtCapacities}
            setDebtCapacities={setDebtCapacities}
            formErrors={debtCapacitiesError}
            setFormErrors={setDebtCapacitiesError}
            allowReview={allowReview}
            isValidating
          />
        </section>
      )}
    </SectionStyles>
  )
}

const RowDebtCapacity = (props) => {
  const { debtCapacity, setDebtCapacity, allowReview } = props

  const { amount, currency: currencyISO, maxTerm, status } = debtCapacity

  return (
    <section className='row-debt-capacity'>
      <h3>
        <Icon name='credit_card' />
        <span>Propuesta de negocio</span>
      </h3>
      <article className='proposal'>
        <section className='currency'>
          <h5>Divisa</h5>
          <h4>{currencyISO}</h4>
        </section>
        <section>
          <h5>Capacidad maxima</h5>
          <h4>{currency(extractNumber(amount))}</h4>
        </section>
        <section>
          <h5>Plazo maximo</h5>
          <h4>{maxTerm}</h4>
        </section>
        {allowReview && (
          <section className='actions'>
            {setDebtCapacity && (
              <>
                <Button
                  className={`danger ${status === 'REJECTED' ? 'checked' : ''}`}
                  type='action'
                  buttonType='outline'
                  onClick={() =>
                    setDebtCapacity({ ...debtCapacity, status: 'REJECTED' })
                  }
                >
                  <Icon name='cancel' type='outlined' />
                </Button>
                <Button
                  className={`success ${
                    status === 'APPROVED' ? 'checked' : ''
                  }`}
                  type='action'
                  buttonType='outline'
                  onClick={() =>
                    setDebtCapacity({ ...debtCapacity, status: 'APPROVED' })
                  }
                >
                  <Icon name='check_circle' type='outlined' />
                </Button>
              </>
            )}
          </section>
        )}
        {!allowReview && <div />}
      </article>
    </section>
  )
}

const SectionStyles = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin-top: 15px;
  padding-inline: 50px;
  padding-block: 20px;

  background: #7516440a;
  max-width: 800px;
  border-radius: 10px;

  height: fit-content;

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 15px;
  }

  .edit-action {
    width: 2.5rem;
    border-radius: 5px;
  }

  .credit-line {
    padding-block: 3rem;
  }

  article.proposal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h4 {
      font-size: 18pt;
      font-weight: bold;
      color: #00172d;
    }

    h5 {
      font-size: 10pt;
      font-weight: normal;
    }

    .currency {
      width: fit-content;
    }

    .editing {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 8px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 5px;

      button {
        display: grid;
        place-items: center;

        width: 2.5rem;
        border-radius: 5px;

        &.success {
          background-color: transparent;
          color: #2ecc71;
          border-color: #2ecc71;
          &.checked {
            background-color: #2ecc71;
            color: white;
          }
        }

        &.danger {
          color: #ff0045;
          border-color: #ff0045;
          background-color: transparent;
          &.checked {
            background-color: #ff0045;
            color: white;
          }
        }
      }
    }
  }
`

export default DebtCapacity

DebtCapacity.propTypes = {
  debtCapacities: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      isApproved: PropTypes.bool,
    })
  ).isRequired,

  allowEdit: PropTypes.bool,
  allowReview: PropTypes.bool,
  setProposal: PropTypes.func,
  setDebtCapacities: PropTypes.func,
  debtCapacitiesError: PropTypes.array,
  setDebtCapacitiesError: PropTypes.func,
}
