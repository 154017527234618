import axios from 'axios'
import { ApiError } from 'utils/schemas/errors'
import { ENDPOINTS } from './config'

export class LoanRepository {
  /**
   * fetch the debts projected for a requested date of a loan
   * @param {Object} query - The query object
   * @param {number} query.loanId - The loan id
   * @param {Date} query.date - The payment date to project
   */
  static async fetchLoanProjection(query) {
    try {
      const { loanId, date } = query

      if (!loanId)
        throw new ApiError('LOAN_ID_REQUIRED', 'The loan id is required', 500)
      if (!date || isNaN(new Date(date)))
        throw new ApiError(
          'DATE_REQUIRED',
          'The projection date is required',
          500
        )

      const { data } = await axios.get(ENDPOINTS.loan.projection, {
        params: { loanId, projectionDate: date },
      })

      const concepts = data.concepts.filter((concept) => concept.totalDebt > 0)

      return {
        total: data.totalDebt,
        terms: data.terms,
        commissions: data.commissions,
        concepts,
      }
    } catch (error) {
      if (error instanceof ApiError) throw error
      if (axios.isAxiosError(error)) {
        const { data } = error.response
        throw new ApiError(data.title, data.detail, data.status)
      }
      throw new ApiError('UNKNOWN_ERROR', error.message, 500)
    }
  }
}
