import { Button, Icon } from '@keoworld/gbl-ui-kit'
import DebtCapacityForm from 'modules/debt-capacity/components/debt-capacity-form'
import styled from 'styled-components'
import { AVAILABLE_CURRENCIES } from 'utils/constants'
import { v4 as UUIDv4 } from 'uuid'

const DebtCapacityFormController = ({
  debtCapacities = [],
  setDebtCapacities,
  formErrors = [],
  setFormErrors,
  isValidating,
}) => {
  const addPropose = () => {
    setDebtCapacities([
      ...debtCapacities,
      {
        key: UUIDv4(),
        currency: '',
        amount: '',
        maxTerm: '',
        status: 'PENDING',
        allowEdit: true,
      },
    ])
    setFormErrors([
      ...formErrors,
      { currency: true, amount: true, status: false },
    ])
  }

  const onClickRemoveCapacity = (index) => () => {
    if (debtCapacities.length < 1) return
    setDebtCapacities(debtCapacities.filter((_, i) => i !== index))
    setFormErrors(formErrors.filter((_, i) => i !== index))
  }

  const onHandleChange = (index) => (newDebtCapacity) => {
    const newFormValues = [...debtCapacities]
    newFormValues[index] = newDebtCapacity
    setDebtCapacities(newFormValues)
  }

  const onHandleErrorChange = (index) => (values) => {
    const newFormErrors = [...formErrors]
    newFormErrors[index] = values
    setFormErrors(newFormErrors)
  }

  return (
    <Styles className='credit-line'>
      {debtCapacities.map((debtCapacity, index) => {
        return (
          <DebtCapacityForm
            key={debtCapacity.key}
            isValidating={isValidating}
            formValues={debtCapacity}
            setFormValues={onHandleChange(index)}
            formErrors={formErrors[index]}
            setFormErrors={onHandleErrorChange(index)}
            onRemove={
              debtCapacities.length > 1 &&
              debtCapacity.status !== 'APPROVED' &&
              onClickRemoveCapacity(index)
            }
          />
        )
      })}

      {debtCapacities.length < AVAILABLE_CURRENCIES.length && (
        <Button className='add-proposal' onClick={() => addPropose()}>
          <span>Añadir propuesta</span>
          <Icon name='add' />
        </Button>
      )}
    </Styles>
  )
}

const Styles = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-block: 15px;

  .add-proposal {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: fit-content;
    height: fit-content;
    padding: 5px;

    span {
      display: none;
    }
    span + span {
      display: inline-block;
    }

    &:hover {
      span {
        display: inline-block;
      }
    }
  }
`

export default DebtCapacityFormController
