import SendIcon from '@mui/icons-material/Send'
import { Button, Skeleton } from '@mui/material'
import { validateFormErrors } from 'containers/form'
import ConfirmationModal from 'modules/debt-capacity/components/confirmation-modal'
import DebtCapacityController from 'modules/debt-capacity/controllers/debt-capacity.controller'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { createCustomerComment } from 'services/customer'
import {
  ApiDebtCapacityRepository,
  PROPOSAL_STATUS,
} from 'services/debt-capacity-suppcards/debtCapacity.repository'
import styled from 'styled-components'
import * as CurrencyFormatter from 'utils/functions/handlers-currency'
import { useAuth } from 'utils/hooks/auth'
import { CUSTOMER_STAGES } from 'utils/schemas/customer'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { TEAMS } from '../schemas/teams.schema'

const DebtCapacityManager = () => {
  const [proposalApproved, setProposalApproved] = useState()
  const [proposalPending, setProposalPending] = useState()
  const [isFetching, setIsFetching] = useState(false)

  const [form, setForm] = useState([])
  const [formErrors, setFormErrors] = useState([])

  const [modalConfiguration, setModalConfiguration] = useState()

  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)
  const { setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()

  const [userRole] = user?.iam?.roles ?? []
  const isReview = Boolean(proposalPending)

  useEffect(() => {
    const obtainProposals = async () => {
      setIsFetching(true)

      const approvedDebtCapacitiesInTDC =
        await ApiDebtCapacityRepository.fetchApprovedDebtCapacities(
          customer.externalId
        )

      const proposalApproved =
        await ApiDebtCapacityRepository.getProposalByBuyerId(
          customer.id,
          PROPOSAL_STATUS.APPROVED
        )

      const proposalPending =
        await ApiDebtCapacityRepository.getProposalByBuyerId(
          customer.id,
          PROPOSAL_STATUS.PENDING
        )

      proposalApproved.debtCapacities = proposalApproved.debtCapacities.map(
        (debtCapacity) => {
          const debtCapacityTDC = approvedDebtCapacitiesInTDC.find(
            (approvedDebtCapacity) =>
              approvedDebtCapacity.id === debtCapacity.id
          )
          return {
            ...debtCapacity,
            amount: CurrencyFormatter.formatCurrency(debtCapacity.amount),
            status: debtCapacity.status ?? PROPOSAL_STATUS.APPROVED,
            usedAmount: CurrencyFormatter.formatCurrency(
              debtCapacityTDC?.usedAmount ?? 0
            ),
            availableAmount: CurrencyFormatter.formatCurrency(
              debtCapacityTDC?.availableAmount ?? 0
            ),
          }
        }
      )

      let form = proposalApproved.debtCapacities ?? []

      if (proposalPending) {
        form = proposalPending.debtCapacities.map((debtCapacity) => ({
          ...debtCapacity,
          amount: CurrencyFormatter.formatCurrency(debtCapacity.amount),
          status: debtCapacity.status ?? PROPOSAL_STATUS.PENDING,
        }))
      }

      setForm(form)
      setFormErrors(
        form.map(() => ({
          currency: false,
          amount: false,
        }))
      )
      setProposalApproved(proposalApproved)
      setProposalPending(proposalPending)
      setIsFetching(false)
    }

    if (customer?.id) {
      obtainProposals()
    }
  }, [customer?.id, customer?.externalId])

  const openConfirmationModal = (action) => {
    switch (action) {
      case 'create':
        setModalConfiguration({
          title: 'Está creando una propuesta de negocio',
          message: `¿Estás seguro de crear una propuesta de negocio?
                    Después de esta se requiere aprobación por
                    el área de riesgo (Underwriting)`,
          icon: 'check_circle',
          requireReason: false,
          action: handleApproveProposal,
        })
        break
      case 'reject':
        setModalConfiguration({
          title: 'Está rechazando la propuesta de negocio',
          message: `¿Estás seguro de rechazar la propuesta de negocio?
                    Después de esta acción no podrá retomar el proceso`,
          icon: 'cancel',
          requireReason: true,
          action: handleRejectProposal,
        })
        break
      case 'approve':
        setModalConfiguration({
          title: 'Está aprobando la propuesta de negocio',
          message: `Por favor ingrese la razón por la cual
                    está aprobando la propuesta de negocio`,
          icon: 'check_circle',
          requireReason: true,
          action: handleApproveProposal,
        })
        break
      default:
    }
  }

  const validateForm = () => {
    if (formErrors.some(validateFormErrors)) {
      throw new FormValidationError(
        `Debes completar correctamente el formulario`,
        'formErrors'
      )
    }

    const countCapacities = form.reduce((acc, capacity) => {
      const value = acc[capacity.currency] ?? 0
      acc[capacity.currency] = value + 1
      return acc
    }, {})

    const isCurrencyDuplicated = Object.values(countCapacities).some(
      (value) => value > 1
    )

    if (isCurrencyDuplicated) {
      throw new FormValidationError(
        `No puede haber una moneda con dos o
          más capacidades de endeudamiento`,
        'currencyDuplicated'
      )
    }
  }

  const createProposal = async () => {
    const debtCapacitiesMap = new Map(
      proposalApproved.debtCapacities.map((capacity) => [
        capacity.currency,
        capacity,
      ])
    )

    const debtCapacitiesChanged = form.filter((debtCapacity) => {
      const proposalCapacity = debtCapacitiesMap.get(debtCapacity.currency)

      if (!proposalCapacity) return true

      return (
        CurrencyFormatter.extractNumber(proposalCapacity.amount) !==
          CurrencyFormatter.extractNumber(debtCapacity.amount) ||
        proposalCapacity.maxTerm !== Number(debtCapacity.maxTerm)
      )
    })

    await ApiDebtCapacityRepository.createProposal({
      customerId: customer.id,
      debtCapacities: debtCapacitiesChanged.map((debtCapacity) => ({
        currency: debtCapacity.currency,
        amount: CurrencyFormatter.extractNumber(debtCapacity.amount),
        maxTerm: Number(debtCapacity.maxTerm),
      })),
    })
  }

  const deleteDebtCapacities = async () => {
    const deletedCapacities = proposalPending.debtCapacities.filter(
      (proposalCapacity) => {
        const isNotDeleted = form.some(
          (debtCapacity) =>
            proposalCapacity.id === debtCapacity.id &&
            debtCapacity.status !== PROPOSAL_STATUS.DELETED
        )
        return !isNotDeleted
      }
    )

    await Promise.all(
      deletedCapacities.map(async (debtCapacity) => {
        await ApiDebtCapacityRepository.deleteDebtCapacity(
          proposalPending.proposalId,
          debtCapacity.id
        )
      })
    )
  }

  const updateDebtCapacities = async () => {
    const updatedCapacities = form.filter((debtCapacity) => {
      if (!debtCapacity.id) return false
      const proposalCapacity = proposalPending.debtCapacities.find(
        (proposalCapacity) => proposalCapacity.id === debtCapacity.id
      )

      if (!proposalCapacity) return false

      return (
        proposalCapacity.maxTerm !== Number(debtCapacity.maxTerm) ||
        proposalCapacity.amount !==
          CurrencyFormatter.extractNumber(debtCapacity.amount)
      )
    })

    await Promise.all(
      updatedCapacities.map(async (debtCapacity) => {
        await ApiDebtCapacityRepository.updateDebtCapacity(
          proposalPending.proposalId,
          debtCapacity.id,
          CurrencyFormatter.extractNumber(debtCapacity.amount),
          Number(debtCapacity.maxTerm)
        )
      })
    )
  }

  const createDebtCapacities = async () => {
    const createdCapacities = form.filter((debtCapacity) => !debtCapacity.id)

    await Promise.all(
      createdCapacities.map(async (debtCapacity) => {
        await ApiDebtCapacityRepository.createDebtCapacity(
          proposalPending.proposalId,
          debtCapacity.currency,
          CurrencyFormatter.extractNumber(debtCapacity.amount)
        )
      })
    )
  }

  const approveProposal = async () => {
    const { proposalId } = proposalPending

    await deleteDebtCapacities()
    await updateDebtCapacities()
    await createDebtCapacities()

    await ApiDebtCapacityRepository.approveProposal(proposalId)
  }

  const handleApproveProposal = async (comment) => {
    try {
      validateForm()

      if (!isReview) {
        await createProposal()
      } else {
        await approveProposal()
      }

      await createCustomerComment({
        comment,
        isPublic: true,
        role: userRole,
        customerId: customer.id,
        uuid: user.uid,
        wfState: CUSTOMER_STAGES.setup.id,
      })

      setAlert({
        type: 'success',
        title: 'Éxito',
        label: !isReview
          ? `Propuesta de capacidad de endeudamiento enviada correctamente,
              debe ser aprobada por el área de riesgo (Underwriting)`
          : `Capacidad de endeudamiento actualizada correctamente`,
      })

      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
    } catch (error) {
      console.error(error.message)
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: `¡Ha ocurrido un error
                  al crear la propuesta!`,
          type: 'error',
        })
      }
    }
  }

  const handleRejectProposal = async (comment) => {
    try {
      await ApiDebtCapacityRepository.rejectProposal(proposalPending.proposalId)

      await createCustomerComment({
        comment,
        isPublic: true,
        role: userRole,
        customerId: customer.id,
        uuid: user.uid,
        wfState: CUSTOMER_STAGES.setup.id,
      })

      setAlert({
        type: 'success',
        title: 'Se ha rechazado exitosamente',
        label: `Se ha rechazado la nueva propuesta, sin embargo,
                no se ha modificado la propuesta aprobada previamente`,
      })

      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMERS)
    } catch (error) {
      console.error(error.message)
      if (error instanceof ApiError) {
        setAlert({
          label: error.message,
          title: 'Error al rechazar la propuesta',
          type: error.statusCode === 400 ? 'warning' : 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: `¡Ha ocurrido un error
                  al crear la propuesta!`,
          type: 'error',
        })
      }
    }
  }

  return (
    <StyledContainer>
      {!isFetching && (
        <section className='actions'>
          {TEAMS.underwriting.members.includes(userRole) && isReview && (
            <Button
              id='reject-proposal'
              variant='outlined'
              color='error'
              onClick={() => openConfirmationModal('reject')}
            >
              Rechazar
            </Button>
          )}
          {((TEAMS.operation.members.includes(userRole) && !isReview) ||
            (TEAMS.underwriting.members.includes(userRole) && isReview)) && (
            <Button
              id='approve-proposal'
              variant='contained'
              color='primary'
              endIcon={<SendIcon />}
              onClick={() =>
                openConfirmationModal(isReview ? 'approve' : 'create')
              }
            >
              {isReview ? 'Aprobar' : 'Continuar'}
            </Button>
          )}
        </section>
      )}
      <section className='proposals'>
        {isFetching && (
          <proposal-skeleton>
            <Skeleton
              variant='rounded'
              animation='wave'
              width={800}
              height={500}
            />
          </proposal-skeleton>
        )}

        <h3>Propuesta aprobada</h3>
        {!isFetching && proposalApproved && (
          <DebtCapacityController
            allowEdit={TEAMS.operation.members.includes(userRole) && !isReview}
            debtCapacities={!isReview ? form : proposalApproved.debtCapacities}
            debtCapacitiesError={formErrors}
            setDebtCapacities={setForm}
            setDebtCapacitiesError={setFormErrors}
          />
        )}

        {proposalPending && <h3>Propuesta pendiente de aprobación</h3>}

        {!isFetching && proposalPending && (
          <DebtCapacityController
            allowEdit={TEAMS.underwriting.members.includes(userRole)}
            debtCapacities={isReview ? form : proposalPending.debtCapacities}
            debtCapacitiesError={formErrors}
            setDebtCapacities={setForm}
            setDebtCapacitiesError={setFormErrors}
          />
        )}
      </section>

      {modalConfiguration && (
        <ConfirmationModal
          handleCloseModal={() => setModalConfiguration(undefined)}
          modalConfiguration={modalConfiguration}
          handleSubmit={modalConfiguration.action}
        />
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.main`
  padding-block: 2rem;
  padding-inline: 3rem;

  h1 {
    font-size: 14pt;
    margin: 0;
    text-transform: uppercase;
  }

  h3 {
    margin-block: 1rem;
    text-transform: uppercase;
  }

  section.actions {
    display: flex;
    justify-content: flex-end;
    margin-block: 1rem;

    button#approve-proposal {
      border-color: #00172d;
      background-color: #00172d;
    }

    button + button {
      margin-inline-start: 1rem;
    }
  }

  section.proposals {
    proposal-skeleton {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    proposal {
      margin-block: 1rem;
    }
  }
`

export default DebtCapacityManager
