import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 *
 * @param {object} form
 * @returns
 */
export const createLine = (form) => axios.post(ENDPOINTS.line.create, form)

export const getContract = async (customerId, status, page = 1, size = 1) => {
  const { data } = await axios.get(ENDPOINTS.contract.find, {
    params: { customerId, page, size, status },
  })
  return data
}
/**
 *
 * @param {object} form
 * @returns
 */
export const createLineCondition = (form) =>
  axios.post(ENDPOINTS.line.createCondition, form)

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const bulkUpload = (formData) =>
  axios.post(ENDPOINTS.bulk.upload, formData)

/**
 *
 * @param {date} dateFrom
 * @param {date} dateTo
 * @param {number} page
 * @param {number} size
 * @returns
 */
export const bulkFind = (dateFrom, dateTo, page, size) =>
  axios.get(ENDPOINTS.bulk.find, { params: { dateFrom, dateTo, page, size } })

/**
 *
 * @param {number} customerId
 * @param {string} status
 * @param {string} currency
 * @returns
 */
export const getCustomerLines = (customerId, status, currency) =>
  axios.get(ENDPOINTS.line.find, { params: { customerId, status, currency } })

export const getLineConditions = async (lineId) => {
  const res = await axios.get(ENDPOINTS.line.findConditions, {
    params: { lineId },
  })
  return res.data
}

/**
 *
 * @param {number} bulkId
 * @param {number} size
 * @param {number} page
 * @returns
 */
export const getCollection = ({ bulkId, size, page }) =>
  axios.get(ENDPOINTS.collection.find, { params: { bulkId, size, page } })

/**
 *
 * @param {Object} body
 * @param {number} body.amount
 * @param {number} body.loanId
 * @param {string} body.madeAt
 * @param {boolean} body.priority
 * @param {string} body.reference
 * @param {string} body.source
 * @param {string} body.type
 * @param {string} [body.concepts]
 * @param {string} [body.reason]
 * @param {iamId} [body.iamId]
 * @returns
 */
export const collectionCreate = async (body) => {
  await axios.post(ENDPOINTS.collection.create, body)
}

/**
 *
 * @param {number} lineId
 * @param {number} lineScore
 * @param {string} customerId
 * @param {string} workflowId
 * @returns
 */
export const assignLine = (lineId, lineScore, customerId, workflowId) =>
  axios.post(ENDPOINTS.line.assign, {
    lineId,
    lineScore,
    customerId,
    workflowId,
  })

/**
 *
 * @param {number} lineId
 * @param {string} customerId
 * @param {string} workflowId
 * @param {string} uuid
 * @param {string} decision
 * @param {string} comment
 * @returns
 */
export const evaluateLine = (
  lineId,
  customerId,
  workflowId,
  uuid,
  decision,
  comment
) =>
  axios.post(ENDPOINTS.line.evaluate, {
    lineId,
    customerId,
    workflowId,
    uuid,
    decision,
    comment,
  })

/**
 *
 * @param {number} customerId
 * @returns
 */
export const financeFind = async (customerId) => {
  const { data } = await axios.get(ENDPOINTS.finance.find, {
    params: { customerId },
  })
  return data
}

/**
 *
 * @param {object} form
 * @returns
 */
export const financeUpdate = async (form) =>
  axios.patch(ENDPOINTS.finance.update, form)

/**
 *
 * @param {number} lineId
 * @returns
 */
export const evaluationFind = async (lineId) => {
  const { data } = await axios.get(ENDPOINTS.line.evaluationFind, {
    params: { lineId },
  })
  return data
}

/**
 *
 * @param {number} lineId
 * @returns
 */
export const getLineInfo = (lineId) =>
  axios.get(ENDPOINTS.line.info, { params: { lineId } })

/**
 *
 * @param {Object} query
 * @param {string} query.state
 * @param {string} query.industry
 * @param {number} query.page
 * @param {number} query.numberOfItems
 * @returns
 */
export const getLineStatements = async (query) => {
  const { state, industry, page, limit } = query

  const { data: lineStatements } = await axios.get(ENDPOINTS.line.statement, {
    params: { state, industry, page, elements: limit },
  })

  return lineStatements
}

/**
 *
 * @param {object} form
 * @param {number} lineId
 * @returns
 */
export const updateLine = (form, lineId) =>
  axios.patch(ENDPOINTS.line.lineUpdate, { ...form.data, id: lineId })

/**
 *
 * @param {object} form
 * @param {number} lineId
 * @returns
 */
export const updateLineCondition = (form, lineId) =>
  axios.patch(ENDPOINTS.line.lineConditionUpdate, { ...form.data, lineId })

/**
 *
 * @param {number} lineId
 * @returns
 */
export const getLineConditionChanges = async (lineId) => {
  const { data } = await axios.get(ENDPOINTS.line.findLineConditionsChange, {
    params: { lineId },
  })
  return data
}
