export const TEAMS = {
  operation: {
    lead: ['bpm.mx-amex-b2b.ope-hd'],
    members: ['bpm.mx-amex-b2b.ope-hd', 'bpm.mx-amex-b2b.ope-jr'],
  },
  underwriting: {
    lead: ['bpm.mx-amex-b2b.rsk-hd', 'bpm.mx-amex-b2b.rsk-vp'],
    members: [
      'bpm.mx-amex-b2b.rsk-hd',
      'bpm.mx-amex-b2b.rsk-vp',
      'bpm.mx-amex-b2b.rsk-jr',
    ],
  },
}
