import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import Form from 'containers/form'
import { DEBT_CAPACITY_SCHEMA } from 'modules/debt-capacity/schemas/debt-capacity-form.schema'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ContainerStyled = styled.article`
  display: grid;
  grid-template-columns: 300px 300px 1fr;
  align-items: center;
  gap: 15px;

  .actions {
  }
`

const DebtCapacityForm = (props) => {
  const {
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    isValidating,
    onRemove,
  } = props

  return (
    <ContainerStyled>
      <Form
        schema={DEBT_CAPACITY_SCHEMA.map((field) => ({
          ...field,
          disabled: field.name === 'currency' && !formValues.allowEdit,
        }))}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        isValidating={isValidating}
      />

      {onRemove && (
        <section className='actions'>
          <IconButton
            aria-label='delete'
            size='large'
            color='error'
            onClick={onRemove}
          >
            <DeleteIcon />
          </IconButton>
        </section>
      )}
    </ContainerStyled>
  )
}

DebtCapacityForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  setFormErrors: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default DebtCapacityForm
