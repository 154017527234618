import { MenuItem } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import PropTypes from 'prop-types'

const MaterialTable = (props) => {
  const {
    columns,
    dataRows = [],
    actions,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    rowCount = 0,
    isLoading = false,
    isRefetching = false,
    enableGlobalFilter = false,
    enableSorting = false,
    enableDensityToggle = false,
    positionActionsColumn = 'last',
  } = props

  return (
    <MaterialReactTable
      localization={MRT_Localization_ES}
      columns={columns}
      data={dataRows}
      enableRowActions
      renderRowActionMenuItems={({ row, closeMenu }) =>
        actions
          .filter((action) => {
            if (action.display) return action.display(row.original, action)
            return true
          })
          .map((action) => {
            return (
              <MenuItem
                key={action.key}
                disabled={
                  action.disabled && action.disabled(row.original, action)
                }
                onClick={() => {
                  action.exec(row.original, action)
                  closeMenu()
                }}
              >
                {action?.label}
              </MenuItem>
            )
          })
      }
      state={{
        columnFilters,
        pagination,
        isLoading,
        showProgressBars: isRefetching,
      }}
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      rowCount={rowCount}
      paginateExpandedRows={false}
      manualPagination
      manualSorting
      manualFiltering
      enableGlobalFilter={enableGlobalFilter}
      enableSorting={enableSorting}
      enableDensityToggle={enableDensityToggle}
      positionActionsColumn={positionActionsColumn}
    />
  )
}

MaterialTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    })
  ).isRequired,
  dataRows: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      exec: PropTypes.func.isRequired,
      display: PropTypes.func,
      disabled: PropTypes.func,
    })
  ),
  pagination: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  columnFilters: PropTypes.object.isRequired,
  setColumnFilters: PropTypes.func.isRequired,
  rowCount: PropTypes.number,
  isLoading: PropTypes.bool,
  isRefetching: PropTypes.bool,
  enableGlobalFilter: PropTypes.bool,
  enableSorting: PropTypes.bool,
  enableDensityToggle: PropTypes.bool,
  positionActionsColumn: PropTypes.oneOf(['first', 'last']),
}

export default MaterialTable
